import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Analytics } from '../../context/Analytics'
import './styles.css'

const Terms = () => {
  const analytics = Analytics()

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return <div className="content">
    <Helmet>
      <title>CISO.DEV - Terms and Conditions</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <div className="policy">
      <h1 id="terms-of-service">Terms and Conditions</h1>
      <p id="date" className="date">Last updated: 2020-01-09</p>
      <h2 id="terms">1. The Terms</h2>
      <p id="p1">By accessing the website at <a href="https://ciso.dev" title="https://ciso.dev">https://ciso.dev</a> (and any other part of the "Site"), you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for complying with any applicable local laws.  If you do not agree with any of these terms, you should not use or access the Site.  The materials contained in this website are protected by applicable copyright and trademark law.</p>
      <h2 id="license">2. License</h2>
      <p id="p2">Permission is granted to temporarily download of copy of the information made available on the Site for personal, non-commercial transitory viewing only.  This is the grant of a license, not a transfer of title, and under this license you may not:</p>
      <ul className="list">
        <li>modify or copy the information in a way that misleads readers</li>
        <li>use the information for any commercial purposes competitive to eCISO, LLC</li>
        <li>remove any copyright or other proprietary notations for the information</li>
        <li>transfer the information to another person or "mirror" the information on any other server</li>
      </ul>
      <p id="p3">This license shall automatically terminate if you violate any of these restrictions and may be terminated by eCISO, LLC at any time.  Upon terminating your viewing of the information or upon termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
      <h2 id="disclaimer">3. Disclaimer</h2>
      <p id="p4">The information on the Site is provided on an "as is" basis, and reflects the professional opinion of contributors and not the official statement or opinion of eCISO, LLC.  As such, eCISO, LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, accuracy of content or statements, or non-infringement of intellectual property or other violation of rights.</p>
      <p id="p5">Furthermore, eCISO, LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the Site or the materials on its website or otherwise relating to such materials or on any websites linked to or from this Site.</p>
      <h2 id="limitations">4. Limitations</h2>
      <p id="p6">In no event shall eCISO, LLC, our directors, employees, agents or its suppliers be liable for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages (including, without limitation, damages for loss of data or profit, damages due to reference or reliance on the information on or provided through the Site, or due to business interruption) arising out of the use or inability to use the materials on the Site, even if eCISO, LLC or an authorized representative of eCISO, LLC has been notified orally or in writing of the possibility of such damage.  Notwithstanding anything to the contrary in these terms and conditions, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to the lesser of the amount paid, if any, by you to eCISO, LLC in exchange for the services or $0.00001 USD.  Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or accidental damages, these limitations may not apply to you, and you may have additional rights.</p>
      <p id="p7">Any failure to exercise or enforce any right or provision of these terms and conditions by eCISO, LLC or any authorized representative of eCISO, LLC shall not operate as a waiver of such right or provision.  eCISO, LLC may assign any or all of our rights or obligations to others at any time and without notice.  If any provision or part of these terms and conditions is determined to be unlawful, void, or otherwise unenforceable, that provision or part of the provision is deemed severable from these terms and conditions and does not affect the validity and enforceability of any remaining provisions.</p>
      <h2 id="accuracy">5. Accuracy</h2>
      <p id="p8">The information and materials appearing on and through the Site could (and likely do) contain technical, factual, typographical, or photographic errorsr.  eCISO, LLC does not warrant that any of the information on or through the Site is accurate, complete, unbiased, appropriate, relevant or current.  eCISO, LLC may make changes to the materials contained on and through the Site at any time without notice, however eCISO, LLC does not make any commitment to update or maintain the materials.</p>
      <h2 id="links">6. Links</h2>
      <p id="p9">The Site may contain links to or from other parties outside of eCISO, LLC's control.  eCISO, LLC has not reviewed all of the sites linked to the Site and is not responsible for the contents of any such linked site.  The inclusion of any link does not imply endorsement by eCISO, LLC of the site or owner of the site.  Use of any such linked website is at the user's own risk.</p>
      <h2 id="modifications">7. Modifications</h2>
      <p id="p10">eCISO, LLC may revise thse terms of service for the Site at any time without notice.  By using the Site or related services, you are agreeing to be bound by the then current version of these terms of service.</p>
      <h2 id="governance">8. Governing Law</h2>
      <p id="p11">These terms and conditions are governed by and construed in accordance with the laws of the State of Utah, in the United States, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
    </div>
  </div>
}

export default Terms
