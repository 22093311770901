import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Analytics } from '../../context/Analytics'
import {
  IconThree,
  IconFour,
  IconEight,
  IconSeventeen,
} from '../../components/SvgIcons'
import Dropdown from '../../components/Dropdown'
import emailIsValid from '../../components/EmailValidator'
import './styles.css'

const illustrationKnowledge = require('../../images/knowledge.png')
const illustrationCredibility = require('../../images/credibility.png')
const illustrationPeople = require('../../images/people.png')

const axios = require('axios')

const Corporate = (props) => {
  const analytics = Analytics()

  const [captcha, setCaptcha] = useState('')
  const [privacyConsent, setPrivacyConsent] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [showEmailTooltip, setShowEmailTooltip] = useState(false)
  const [formTriggered, setFormTriggered] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formError, setFormError] = useState(false)
  const [refreshIn, setRefreshIn] = useState(5)
  const [subscription, setSubscription] = useState({ users: 0 })
  const [price, setPrice] = useState({
    users: 0,
    total: '699',
    tag: 'Plans starting at',
  })
  const [dropdowns, setDropdowns] = useState({
    usersList: [
      {
        id: 0,
        value: 799,
        code: 3,
        title: '3 team members',
        selected: false,
        key: 'usersList',
      },
      {
        id: 1,
        value: 749,
        code: 4,
        title: '4 team members',
        selected: false,
        key: 'usersList',
      },
      {
        id: 2,
        value: 699,
        code: 5,
        title: '5+ team members',
        selected: false,
        key: 'usersList',
      },
    ]
  })
  const [showDropdownTooltip, setShowDropdownTooltip] = useState({
    usersList: false,
  })

  let timer
  let refreshTimer = {
    time: {},
    seconds: 5,
  }

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60))
    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)
    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)
    let obj = {
      'h': hours,
      'm': minutes,
      's': seconds,
    }
    return obj
  }

  const refreshCountdown = () => {
    let seconds = refreshTimer.seconds - 1
    if (seconds === 0) {
      clearInterval(timer)
      window.location.reload()
    }
    refreshTimer = {
      time: secondsToTime(seconds),
      seconds: seconds,
    }
    setRefreshIn(seconds)
  }

  const refreshTimerStart = () => {
    timer = setInterval(refreshCountdown, 1000)
  }

  const captchaOnChange = (e) => {
    setCaptcha(e.target.value)
  }

  const privacyConsentOnChange = () => {
    setPrivacyConsent(!privacyConsent)
  }

  const emailOnBlur = () => {
    if (emailValid && emailAddress !== '') {
      setShowEmailTooltip(false)
    } else {
      setShowEmailTooltip(true)
    }
  }

  const emailOnChange = (e) => {
    setEmailAddress(e.target.value)
    if (e.target.value.length < 7) {
      // Too short to be an email address
      return
    }
    if (emailIsValid(e.target.value)) {
      setEmailValid(true)
      setShowEmailTooltip(false)
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE'
    } else {
      setEmailValid(false)
      e.target.style.borderColor = '#dfdfdf'
    }
  }

  const resetThenSet = (id, key) => {
    let dropdown = dropdowns
    dropdown[key].forEach(item => item.selected = false)
    dropdown[key][id].selected = true
    setSubscription({
      users: key === 'usersList' ? dropdown[key][id].code : subscription.users,
    })
    let priceUpdate = {
      users: key === 'usersList' ? dropdown[key][id].value : price.users,
      total: price.total,
      tag: price.tag,
    }
    if (priceUpdate.users !== 0) {
      priceUpdate.total = priceUpdate.users
      priceUpdate.tag = 'Get a mentor for only'
    }
    setPrice(priceUpdate)
    let tooltips = showDropdownTooltip
    tooltips[key] = false
    setShowDropdownTooltip(tooltips)
    setDropdowns(dropdown)
    analytics.startSingleEvent({
      href: window.location.href,
      type: `form interaction`,
    })
  }

  const formHandler = (e) => {
    e.preventDefault()
    // Check if required fields are filled out
    if (subscription.users === 0 || !emailValid) {
      if (subscription.users === 0) {
        // Trigger tooltip
        let tooltips = showDropdownTooltip
        tooltips.usersList = true
        setShowDropdownTooltip(tooltips)
      }
      if (!emailValid) {
        // Trigger tooltip
        setShowEmailTooltip(true)
      }
      analytics.startSingleEvent({
        href: window.location.href,
        msg: 'form incomplete',
        type: 'error'
      })
      return
    }
    setFormTriggered(!formTriggered)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'form submit',
    })
    // Check captcha
    if (captcha !== '') {
      setFormError('Invalid captcha provided!')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `Invalid captcha provided`,
        type: `error`,
      })
      refreshTimerStart()
      return
    }
    // Post to API
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/v1/subscribe/corporate`, {
        captcha: captcha,
        email: emailAddress,
        users: subscription.users,
        consent: privacyConsent
      }).then((res) => {
        // Show success modal
        setFormSuccess(true)
        refreshTimerStart()
      }).catch((err) => {
        setFormError('Error connecting to API!')
        analytics.startSingleEvent({
          href: window.location.href,
          msg: `${err.message}`,
          type: `error`,
        })
        refreshTimerStart()
      })
    } catch (err) {
      setFormError('An error occurred')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `${err.message}`,
        type: `error`,
      })
      refreshTimerStart()
    }
  }

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return <div className="content">
    <Helmet>
      <title>CISO.DEV - Security mentors for your business</title>
      <meta name="description" content="Security mentors as a service for your business.  Get unlimited access to cyber security mentors for your security team today." />
      <meta name="keywords" content="mentor as a service,professional development,security team,security mentor,mentor for security,mentor for CISO,cyber,get a mentor,security teams,infosec,mentor benefits,offer mentoring as a perk,security career,career growth,CISO,leadership development,eCISO,security for everyone" />
    </Helmet>
    <div className="corporate">
      <div className="hero">
        <div className="container">
          <h1>Elevate your security team.</h1>
          <h2><HashLink smooth to="/corporate/#subscribe" title="Contact us to get the mentor your team needs" className="cta">Get unlimited access</HashLink> to security mentors.</h2>
        </div>
      </div>
      <div className="tagline">
        <h3><strong><span className="mobileOnly">Security </span>Mentor:</strong> An experienced advisor and trusted personal advocate.</h3>
      </div>
      <div className="overview">
        <h2>Your team. Our experience.</h2>
        <div className="benefitsContainer">
          <div className="benefit benefitOne">
            <img src={illustrationKnowledge} alt="Illustration of a woman and a man with a glowing lightbulb between them" title="Leverage our knowledge." />
            <div className="benefitWrap">
              <h3>Leverage our knowledge.</h3>
              <p><strong>You've hired smart people.</strong> Solve those hard problems even faster by giving them the time they need with mentors that know where the pitfalls and shortcuts are.</p>
              <div className="linkWrap">
                <HashLink smooth to="/corporate/#subscribe">Get started</HashLink>
              </div>
            </div>
          </div>
          <div className="benefit benefitTwo">
            <img src={illustrationCredibility} alt="Illustration of a woman directing attention to a certification or award" title="Enhance your credibility." />
            <div className="benefitWrap">
              <h3>Enhance your credibility.</h3>
              <p><strong>You're working hard.</strong> Establish trust with your customers by leveraging the advice and experience of respected security professionals to magnify your efforts.</p>
              <div className="linkWrap">
                <HashLink smooth to="/corporate/#subscribe">Learn more</HashLink>
              </div>
            </div>
          </div>
          <div className="benefit benefitThree">
            <img src={illustrationPeople} alt="Illustration of" title="Leverage our knowledge." />
            <div className="benefitWrap">
              <h3>Accelerate your growth.</h3>
              <p className="hidden"><strong>You're moving fast.</strong> Unlock your potential with the guidance of seasoned security leaders to help you accomplish even more.</p>
              <p><strong>You're moving fast.</strong> Maximize your efforts and unlock your potential with the guidance of seasoned security leaders to help you prioritize and accomplish even more.</p>
              <div className="linkWrap">
                <HashLink smooth to="/corporate/#subscribe">Get a mentor</HashLink>
              </div>
            </div>
          </div>
        </div>
        <h2>Your people. Real progress.</h2>
        <div className="detailsContainer">
          <div className="cardWrap">
            <div className="card">
              <div className="cardTitle">
                <h3>Achieve your goals.</h3>
              </div>
              <div className="cardSubject">
                <p><strong>Set SMART goals</strong> for members of your security team and have our mentors guide them towards success.</p>
              </div>
              <div className="icon yellow">
                <IconFour />
              </div>
            </div>
            <div className="card">
              <div className="cardTitle">
                <h3>Increase your confidence.</h3>
              </div>
              <div className="cardSubject">
                <p><strong>Build a story of success</strong> with frequent check-ins to keep your teams focused, motivated and on track.</p>
              </div>
              <div className="icon blue">
                <IconEight />
              </div>
            </div>
            <div className="card">
              <div className="cardTitle">
                <h3>Sharpen their skills.</h3>
              </div>
              <div className="cardSubject">
                <p><strong>Create a clear path</strong> for your team to progress by identifying skills gaps and challenging their limits in a safe environment.</p>
              </div>
              <div className="icon green">
                <IconThree />
              </div>
            </div>
            <div className="card">
              <div className="cardTitle">
                <h3>Show you care.</h3>
              </div>
              <div className="cardSubject">
                <p><strong>Show your support</strong> for the people you hire by offering mentoring as benefit for their professional development.</p>
              </div>
              <div className="icon orange">
                <IconSeventeen />
              </div>
            </div>
          </div>
          <div className="detail">
            <h3>Invest in your people.</h3>
            <p>We connect you with seasoned mentors to support the members of your security team through regular discussions, video chats, and personalized challenges to encourage their professional development.</p>
            <div className="linkWrap">
              <HashLink smooth to="/corporate/#subscribe">Get started today</HashLink>
            </div>
          </div>
        </div>
        <h2>Your plan. Three steps.</h2>
        <div className="tracksContainer">
          <div className="tracks">
            <div className="track">
              <h3>1. Pick your plan</h3>
              <p><strong>Choose how many team members to include.</strong> Plans are priced per team member per month, so that you can choose (or change) who in your company needs to connect with their mentor each month.</p>
            </div>
            <div className="track">
              <h3>2. Set your goals</h3>
              <p><strong>Create an actionable plan.</strong> We'll help you set SMART goals (Specific, Measurable, Achievable, Relevant, Timely) for each person we mentor with practical metrics to track their progress.</p>
            </div>
            <div className="track">
              <h3>3. Book your time</h3>
              <p><strong>Schedule time for your team.</strong> Create regular recurring blocks for your team members to connect with their mentor over video chat as often as they need to stay focused and productive.</p>
              <div className="linkWrap">
                <HashLink smooth to="/corporate/#subscribe">Get started</HashLink>
              </div>
            </div>
          </div>
        </div>
        <h2>Good for them. Good for business.</h2>
        <div className="business">
          <img src={illustrationPeople} alt="Illustration of a man and a woman lifting a pie chart upwards together" title="Build your people." />
          <div className="businessWrap">
            <h3>Build your people.</h3>
            <p><strong>Invest in your security team.</strong> Give your employees the support they need to rock their role. Get a mentor for your team to align security with your company's goals.</p>
            <div className="linkWrap">
              <HashLink smooth to="/corporate/#subscribe">Learn more</HashLink>
            </div>
          </div>
        </div>
      </div>
      <div className="calculator" id="subscribe">
        <h2>Plans designed by you.</h2>
        <p className="tag">Affordable options for every business.</p>
        <div className="calculatorWrap">
          <form onSubmit={e => { e.preventDefault() }} id="signup-form" className="signupform calc">
            <div className="optionWrap">
              <label className="emailWrap captcha">
                <h3>What is this captcha?</h3>
                <input onChange={captchaOnChange} type="email" className="email" placeholder="" />
              </label>
              <h3>Choose your number of users.</h3>
              <p>How many people are on your team?</p>
              <Dropdown id="userDropdown" title="Pick your number of users" list={dropdowns.usersList} resetThenSet={resetThenSet} tooltipMessage="Select the number of users" showTooltip={showDropdownTooltip.usersList} />
              <label className="emailWrap">
                <h3>What is your email?</h3>
                <p>Enter your email address to get started.</p>
                <input id="userEmail" onBlur={emailOnBlur} onChange={emailOnChange} type="email" className="email" placeholder="" required />
                <div className={ showEmailTooltip ? "emailTooltip" : "emailTooltip hidden" }>
                  <p>Enter a valid email address</p>
                </div>
              </label>
              <label className="privacyWrap">
                <input onChange={privacyConsentOnChange} defaultChecked={privacyConsent} type="checkbox" className="privacy" />
                <span className="privacyCheckbox"></span>
                <p className="privacyLabel">I accept the terms of the <a href="/privacy">privacy policy</a>.</p>
              </label>
              <div className="mobileCta">
                <p className="sub">Click the button below to request more information today.</p>
                <p className="sub terms">By submitting this form, you agree to the <Link to="/terms">terms and conditions</Link>.</p>
                <div className="linkWrap">
                  <a href="#subscribe-button" onClick={formHandler} title="Click here to schedule a call with sales" className="subscribeButton">Schedule a Call</a>
                  <div className={`formSubmitOverlay ${ formTriggered ? "open" : "" } ${ formError ? "error" : "" } ${ formSuccess ? "success" : "" }`}>
                    <p className="loading mobile">Loading<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="loading wide">Texting your mentor<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="errorMessage">Oops, an error occurred!</p>
                    <p className="errorMessage sub">Please refresh the page and try again.</p>
                    <p className="errorMessage sub small">(We'll automatically refresh this page in {refreshIn}...)</p>
                    <p className="successMessage">Got it, thanks for reaching out!</p>
                    <p className="successMessage sub">We'll contact you shortly to schedule a call.</p>
                    <p className="successMessage sub small">(This page will automatically refresh in {refreshIn}...)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="priceWrap">
              <div className="price">
                <h4>{price.tag}</h4>
                <p className="total"><span className="currency">$</span>{price.total}<span className="frequency">/user/month</span></p>
                <p className="sub mobile">Fill out the form to build your plan.</p>
                <p className="sub wide">Click the button below to request more information today.</p>
                <p className="sub wide terms">By submitting this form, you agree to the <Link to="/terms" target="_blank">terms and conditions</Link>.</p>
                <div className="sub wide linkWrap">
                  <a href="#subscribe-button" onClick={formHandler} title="Click here to schedule a call with sales" className="subscribeButton">Schedule a Call</a>
                  <div className={`formSubmitOverlay ${ formTriggered ? "open" : "" } ${ formError ? "error" : "" } ${ formSuccess ? "success" : "" }`}>
                    <p className="loading mobile">Loading<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="loading wide">Texting your mentor<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="errorMessage">Oops, an error occurred!</p>
                    <p className="errorMessage sub">Please refresh the page and try again.</p>
                    <p className="errorMessage sub small">(We'll automatically refresh this page in {refreshIn}...)</p>
                    <p className="successMessage">Got it, thanks for reaching out!</p>
                    <p className="successMessage sub">We'll contact you shortly to schedule a call.</p>
                    <p className="successMessage sub small">(This page will automatically refresh in {refreshIn}...)</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default Corporate
