import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Analytics } from '../../context/Analytics'
import './styles.css'

const illustrationCheck = require('../../images/okok.gif')

const SuccessPage = (props) => {
  const analytics = Analytics()

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  useEffect(() => {
    let location = props.history.location
    let paramsList = location.search
    let valid = false
    if (typeof paramsList !== 'undefined') {
      paramsList = paramsList.replace('?', '')
      paramsList = paramsList.split('&')
      paramsList.forEach(query => {
        const item = query.split('=')
        if (item[0] === 'code') {
          let code
          try {
            code = atob(item[1])
            const UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
            valid = UUID.test(code)
          } catch (err) {
            analytics.startSingleEvent({
              href: window.location.href,
              msg: err.message,
              type: 'error',
            })
          }
        }
      })
      console.log('Should redirect:', !valid)
      delete location.search
      props.history.push(location)
    }
    if (!valid) {
      console.log('Redirecting...')
      props.history.push({
        hash: '',
        key: location.key,
        pathname: '/',
        search: '',
        state: undefined
      })
    }
  }, [])

  return <div className="content">
    <Helmet>
      <title>CISO.DEV - Thanks for subscribing!</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <div className="fullpage success">
      <div className="wrapper">
        <div className="successHeader">
          <Link to="/" title="Return to CISO.DEV">CISO.DEV</Link>
        </div>
        <div className="successWrap">
          <img src={illustrationCheck} alt="Animated green checkmark illustration" title="Thank you for registering with CISO.DEV" />
          <h1>Thanks for investing in you - You are worth it!</h1>
          <p>Check your email to confirm your account and complete your registration.</p>
        </div>
        <div className="successFooter">
          <p className="copyright">&copy; eciso, llc. 2019 &bull; <Link to="/terms" title="Read our Terms of Service">terms</Link> &bull; <Link to="/privacy" title="Read our Privacy Policy">privacy</Link></p>
        </div>
      </div>
    </div>
  </div>
}

export default SuccessPage
