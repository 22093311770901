import React, { Component } from 'react'
import uuid from 'uuid/v4'
import axios from 'axios'

const AnalyticsContext = React.createContext()

class AnalyticsProvider extends Component {
  constructor(props){
    super(props)
    this.state = {
      session: '',
      sessionEnded: false,
      events: [],
      current: {},
      startSession: () => {
        if (this.state.session === '') {
          this.setState({
            session: {
              id: uuid(),
              startedAt: new Date().getTime(),
              ref: document.referrer,
              ua: navigator.userAgent,
              lang: navigator.language,
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
              pages: 0,
            },
            events: [{
              time: new Date().getTime(),
              href: window.location.href,
              type: 'start session',
              dur: 0,
            }]
          })
        }
      },
      endSession: () => {
        if (this.state.sessionEnded) return
        this.setState({
          sessionEnded: true
        })
        let session = this.state.session
        let events = this.state.events
        events.push({
          time: new Date().getTime(),
          href: window.location.href,
          type: 'end session',
          dur: 0
        })
        session.dur = new Date().getTime() - session.startedAt
        session.latency = window.performance.timing.responseEnd - window.performance.timing.fetchStart
        session.pageLoad = window.performance.timing.loadEventEnd - window.performance.timing.responseEnd
        events.forEach(event => { if (event.type === 'page') { session.pages++ }})
        if (session.pages === 0 && session.dur >= 5000) session.pages = 1
        //axios.post(`${process.env.REACT_APP_API_URL}/v1/t`, {
        axios.post('http://localhost:4000/v1/t', {
          session: session,
          events: events,
        }).catch((err) => {
          // Fail silently
        })
      },
      startEvent: (e) => {
        let event = {
          time: new Date().getTime(),
          href: e.href,
          type: e.type
        }
        if (e.msg) {
          event.msg = e.msg
        }
        this.setState({
          current: event
        })
      },
      endEvent: () => {
        let event = this.state.current
        let events = this.state.events
        event.dur = new Date().getTime() - event.time
        events.push(event)
        this.setState({
          events: events
        })
      },
      startSingleEvent: (e) => {
        let event = {
          time: new Date().getTime(),
          href: e.href,
          type: e.type,
          dur: 0,
        }
        if (e.msg) {
          event.msg = e.msg
        }
        let events = this.state.events
        events.push(event)
        this.setState({
          events: events
        })
      },
    }

    this.startSessionHandler = this.startSessionHandler.bind(this)
    this.endSessionHandler = this.endSessionHandler.bind(this)
  }

  componentWillMount() {
    window.addEventListener('load', this.startSessionHandler)
    window.addEventListener('pagehide', this.endSessionHandler)
    window.addEventListener('beforeunload', this.endSessionHandler)
    window.addEventListener('unload', this.endSessionHandler)
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    if (iOS) window.addEventListener('blur', this.endSessionHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.startSessionHandler)
    window.removeEventListener('pagehide', this.endSessionHandler)
    window.removeEventListener('beforeunload', this.endSessionHandler)
    window.removeEventListener('unload', this.endSessionHandler)
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    if (iOS) window.removeEventListener('blur', this.endSessionHandler)
  }

  startSessionHandler() {
    this.state.startSession()
  }

  endSessionHandler(e) {
    this.state.endSession()
  }

  render() {
    return <AnalyticsContext.Provider value={this.state}>
      {this.props.children}
    </AnalyticsContext.Provider>
  }
}

const Analytics = () => {
  const context = React.useContext(AnalyticsContext)
  if (typeof context === 'undefined') {
    throw new Error('Analytics context must be used within an AnalyticsProvider')
  }
  return context
}

export { AnalyticsProvider, Analytics }
