import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Analytics } from '../../context/Analytics'
import {
  IconOne,
  IconTwo,
  IconThree,
  IconFour,
  IconFive,
  IconSix,
  IconSeven,
  IconEight,
  IconNine,
  IconTen,
  IconEleven,
  IconTwelve,
  IconThirteen,
  IconFourteen,
  IconFifteen,
  IconSixteen,
  IconSeventeen,
  IconEighteen,
} from '../../components/SvgIcons'
import TinySlider from 'tiny-slider-react'
import Dropdown from '../../components/Dropdown'
import emailIsValid from '../../components/EmailValidator'
import './styles.css'

const illustrationJob = require('../../images/job.png')
const illustrationRaise = require('../../images/raise.png')
const illustrationConfidence = require('../../images/confidence.png')
const illustrationPeople = require('../../images/people.png')

const axios = require('axios')

const Home = (props) => {
  const analytics = Analytics()

  const [captcha, setCaptcha] = useState('')
  const [privacyConsent, setPrivacyConsent] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [showEmailTooltip, setShowEmailTooltip] = useState(false)
  const [formTriggered, setFormTriggered] = useState(false)
  const [formError, setFormError] = useState(false)
  const [refreshIn, setRefreshIn] = useState(5)
  const [subscription, setSubscription] = useState({
    career: 0,
    hours: 0,
  })
  const [price, setPrice] = useState({
    career: 0,
    hours: 0,
    terms: 0,
    total: '49',
    tag: 'Plans starting at',
    isStudent: false,
  })
  const [dropdowns, setDropdowns] = useState({
    careerList: [
      {
        id: 0,
        value: 0.5,
        code: 1,
        title: 'Student Track',
        selected: false,
        key: 'careerList',
      },
      {
        id: 1,
        value: 1.0,
        code: 2,
        title: 'Individual Contributor',
        selected: false,
        key: 'careerList',
      },
      {
        id: 2,
        value: 1.5,
        code: 3,
        title: 'Management Track',
        selected: false,
        key: 'careerList',
      },
      {
        id: 3,
        value: 2.5,
        code: 4,
        title: 'Executive Track',
        selected: false,
        key: 'careerList',
      },
    ],
    hoursList: [
      {
        id: 0,
        value: 100,
        code: 1,
        title: '1 hour',
        selected: false,
        key: 'hoursList',
      },
      {
        id: 1,
        value: 190,
        code: 2,
        title: '2 hours',
        selected: false,
        key: 'hoursList',
      },
      {
        id: 2,
        value: 280,
        code: 3,
        title: '3 hours',
        selected: false,
        key: 'hoursList',
      },
      {
        id: 3,
        value: 370,
        code: 4,
        title: '4 hours',
        selected: false,
        key: 'hoursList',
      },
    ],
    termsList: [
      {
        id: 0,
        value: 0.0,
        title: 'Monthly payments',
        selected: true,
        key: 'termsList',
      },
      {
        id: 1,
        value: 0.25,
        title: 'Income sharing agreement',
        selected: false,
        key: 'termsList',
      },
    ],
  })
  const [showDropdownTooltip, setShowDropdownTooltip] = useState({
    careerList: false,
    hoursList: false,
  })

  let timer
  let refreshTimer = {
    time: {},
    seconds: 5,
  }

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60))
    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)
    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)
    let obj = {
      'h': hours,
      'm': minutes,
      's': seconds,
    }
    return obj
  }

  const refreshCountdown = () => {
    let seconds = refreshTimer.seconds - 1
    if (seconds === 0) {
      clearInterval(timer)
      window.location.reload()
    }
    refreshTimer = {
      time: secondsToTime(seconds),
      seconds: seconds,
    }
    setRefreshIn(seconds)
  }

  const refreshTimerStart = () => {
    timer = setInterval(refreshCountdown, 1000)
  }

  const captchaOnChange = (e) => {
    setCaptcha(e.target.value)
  }

  const privacyConsentOnChange = () => {
    setPrivacyConsent(!privacyConsent)
  }

  const emailOnBlur = () => {
    if (emailValid && emailAddress !== '') {
      setShowEmailTooltip(false)
    } else {
      setShowEmailTooltip(true)
    }
  }

  const emailOnChange = (e) => {
    setEmailAddress(e.target.value)
    if (e.target.value.length < 7) {
      // Too short to be an email address
      return
    }
    if (emailIsValid(e.target.value)) {
      setEmailValid(true)
      setShowEmailTooltip(false)
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE'
    } else {
      setEmailValid(false)
      e.target.style.borderColor = '#dfdfdf'
    }
  }

  const resetThenSet = (id, key) => {
    let dropdown = dropdowns
    dropdown[key].forEach(item => item.selected = false)
    dropdown[key][id].selected = true
    setSubscription({
      track: key === 'careerList' ? dropdown[key][id].code : subscription.track,
      hours: key === 'hoursList' ? dropdown[key][id].code : subscription.hours,
    })
    let priceUpdate = {
      career: key === 'careerList' ? dropdown[key][id].value : price.career,
      hours: key === 'hoursList' ? dropdown[key][id].value : price.hours,
      terms: key === 'termsList' ? dropdown[key][id].value : price.terms,
      isStudent: key === 'careerList' ? id === 0 : price.isStudent,
      total: price.total,
      tag: price.tag,
    }
    if (priceUpdate.career !== 0 && priceUpdate.hours !== 0) {
      let total = ((priceUpdate.career * priceUpdate.hours) * (1 + (priceUpdate.career * priceUpdate.terms)))
      priceUpdate.total = priceUpdate.hours === dropdowns.hoursList[0].value ? total - 1 : total
      priceUpdate.tag = 'Get a mentor for only'
    }
    setPrice(priceUpdate)
    let tooltips = showDropdownTooltip
    tooltips[key] = false
    setShowDropdownTooltip(tooltips)
    setDropdowns(dropdown)
    analytics.startSingleEvent({
      href: window.location.href,
      type: `form interaction`,
    })
  }

  const formHandler = (e) => {
    e.preventDefault()
    // Check if required fields are filled out
    if (subscription.career === 0 || subscription.hours === 0 || !emailValid) {
      let tooltips = showDropdownTooltip
      if (subscription.career === 0) {
        tooltips.careerList = true
      }
      if (subscription.hours === 0) {
        tooltips.hoursList = true
      }
      // Trigger dropdown tooltips
      setShowDropdownTooltip(tooltips)
      if (!emailValid) {
        // Trigger tooltip
        setShowEmailTooltip(true)
      }
      analytics.startSingleEvent({
        href: window.location.href,
        msg: 'form incomplete',
        type: 'error'
      })
      return
    }
    setFormTriggered(!formTriggered)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'form submit',
    })
    // Check captcha
    if (captcha !== '') {
      setFormError('Invalid captcha provided!')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `Invalid captcha provided`,
        type: `error`,
      })
      refreshTimerStart()
      return
    }
    // Initialize Stripe
    let stripe
    try {
      stripe = window.Stripe(`${process.env.REACT_APP_STRIPE_PUBKEY}`)
    } catch (err) {
      let e = new Error('Error connecting to Stripe!')
      setFormError(e.message)
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `${e.message}`,
        type: 'error',
      })
      refreshTimerStart()
      return
    }
    // Post to API
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/v1/subscribe`, {
        captcha: captcha,
        email: emailAddress,
        track: subscription.track,
        hours: subscription.hours,
        consent: privacyConsent
      }).then((res) => {
        stripe.redirectToCheckout({
          sessionId: res.data.session
        }).then((result) => {
        }).catch((err) => {
          setFormError('Error connecting to Stripe!')
          analytics.startSingleEvent({
            href: window.location.href,
            msg: `${err.message}`,
            type: `error`,
          })
          refreshTimerStart()
        })
      }).catch((err) => {
        setFormError('Error connecting to API!')
        analytics.startSingleEvent({
          href: window.location.href,
          msg: `${err.message}`,
          type: `error`,
        })
        refreshTimerStart()
      })
    } catch (err) {
      setFormError('An error occurred!')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `${err.message}`,
        type: `error`,
      })
      refreshTimerStart()
    }
  }

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  const sliderSettings = {
    items: 2,
    center: false,
    controls: false,
    autoplay: true,
    speed: 450,
    autoplayTimeout: 3500,
    autoplayHoverPause: false,
    swipeAngle: false,
    touch: false,
    fixedWidth: 130,
    gutter: 12,
    edgePadding: 75,
    responsive: {
      '335': {
        items: 2,
        center: true,
      },
      '420': {
        items: 3,
        center: true,
        edgePadding: 30,
      },
    },
  }

  return <div className="content">
    <div>
      <div className="hero">
        <div className="container">
          <h1>Level up your <span className="wide">security </span> career.</h1>
          <h2><HashLink smooth to="/#subscribe" title="Sign up to get the mentor you deserve" className="cta">Get a mentor</HashLink> that{"'"}s been there.</h2>
        </div>
      </div>
      <div className="tagline">
        <h3><strong><span className="mobileOnly">Security </span>Mentor:</strong> An experienced advisor and trusted personal advocate.</h3>
      </div>
      <div className="overview">
        <h2>Your career. Real results.</h2>
        <div className="benefitsContainer">
          <div className="benefit benefitOne">
            <img src={illustrationJob} alt="Illustration of a woman standing in front of office buildings" title="Get the job." />
            <div className="benefitWrap">
              <h3>Get the job.</h3>
              <p><strong>You deserve it.</strong> Learn what it takes to make the next step in your career with experienced mentors that know what modern companies are looking for.</p>
              <div className="linkWrap">
                <HashLink smooth to="/#subscribe">Get started</HashLink>
              </div>
            </div>
          </div>
          <div className="benefit benefitTwo">
            <img src={illustrationRaise} alt="Illustration of a woman reaching up to take money" title="Get the raise." />
            <div className="benefitWrap">
              <h3>Get the raise.</h3>
              <p><strong>You are worth it.</strong> Secure the compensation that you deserve with the right coaching from senior executive mentors.</p>
              <div className="linkWrap">
                <HashLink smooth to="/#subscribe">Learn more</HashLink>
              </div>
            </div>
          </div>
          <div className="benefit benefitThree">
            <img src={illustrationConfidence} alt="Illustration of a man celebrating with a balloon and confetti" title="Get the confidence." />
            <div className="benefitWrap">
              <h3>Get the confidence.</h3>
              <p><strong>You have earned it.</strong> Realize your strengths and build new skills with our seasoned mentors in technical and leadership roles.</p>
              <div className="linkWrap">
                <HashLink smooth to="/#subscribe">Get a mentor</HashLink>
              </div>
            </div>
          </div>
        </div>
        <h2>Your mentor. Real benefits.</h2>
        <div className="slideWrap">
          <TinySlider settings={sliderSettings}>
            <div className="detail" key={0}>
              <div className="card">
                <h3>Networking</h3>
                <div className="icon green">
                  <IconOne />
                </div>
              </div>
            </div>
            <div className="detail" key={1}>
              <div className="card">
                <h3>References</h3>
                <div className="icon yellow">
                  <IconTwo />
                </div>
              </div>
            </div>
            <div className="detail" key={2}>
              <div className="card">
                <h3>Skills Growth</h3>
                <div className="icon blue">
                  <IconThree />
                </div>
              </div>
            </div>
            <div className="detail" key={3}>
              <div className="card">
                <h3>Compensation</h3>
                <div className="icon red">
                  <IconFour />
                </div>
              </div>
            </div>
            <div className="detail" key={4}>
              <div className="card">
                <h3>Personal Branding</h3>
                <div className="icon orange">
                  <IconFive />
                </div>
              </div>
            </div>
            <div className="detail" key={5}>
              <div className="card">
                <h3>One-on-Ones</h3>
                <div className="icon blue">
                  <IconSix />
                </div>
              </div>
            </div>
            <div className="detail" key={6}>
              <div className="card">
                <h3>Interview Prep</h3>
                <div className="icon orange">
                  <IconSeven />
                </div>
              </div>
            </div>
            <div className="detail" key={7}>
              <div className="card">
                <h3>Coaching</h3>
                <div className="icon yellow">
                  <IconEight />
                </div>
              </div>
            </div>
            <div className="detail" key={8}>
              <div className="card">
                <h3>Career Planning</h3>
                <div className="icon green">
                  <IconNine />
                </div>
              </div>
            </div>
            <div className="detail" key={9}>
              <div className="card">
                <h3>Certifications</h3>
                <div className="icon red">
                  <IconTen />
                </div>
              </div>
            </div>
            <div className="detail" key={10}>
              <div className="card">
                <h3>Introductions</h3>
                <div className="icon blue">
                  <IconEleven />
                </div>
              </div>
            </div>
            <div className="detail" key={11}>
              <div className="card">
                <h3>Soft Skills</h3>
                <div className="icon green">
                  <IconTwelve />
                </div>
              </div>
            </div>
            <div className="detail" key={12}>
              <div className="card">
                <h3>Guidance</h3>
                <div className="icon orange">
                  <IconThirteen />
                </div>
              </div>
            </div>
            <div className="detail" key={13}>
              <div className="card">
                <h3>Video Chats</h3>
                <div className="icon blue">
                  <IconFourteen />
                </div>
              </div>
            </div>
            <div className="detail" key={14}>
              <div className="card">
                <h3>Honest Feedback</h3>
                <div className="icon green">
                  <IconFifteen />
                </div>
              </div>
            </div>
            <div className="detail" key={15}>
              <div className="card">
                <h3>Knowledge Sharing</h3>
                <div className="icon orange">
                  <IconSixteen />
                </div>
              </div>
            </div>
            <div className="detail" key={16}>
              <div className="card">
                <h3>Recognition</h3>
                <div className="icon yellow">
                  <IconSeventeen />
                </div>
              </div>
            </div>
            <div className="detail" key={17}>
              <div className="card">
                <h3>Resume Development</h3>
                <div className="icon blue">
                  <IconEighteen />
                </div>
              </div>
            </div>
          </TinySlider>
        </div>
        <div className="detailsContainer">
          <div className="detail">
            <h3>Own your security career.</h3>
            <p>We connect you with seasoned mentors to help you take charge of your security career.  Leverage their network, build your personal brand, and reach your goals together with a mentor that's been there before.</p>
            <div className="linkWrap">
              <HashLink smooth to="/#subscribe">Sign up today</HashLink>
            </div>
          </div>
        </div>
        <h2>Your path. Four tracks.</h2>
        <h2 className="hidden">Your path. Three tracks.</h2>
        <div className="tracksContainer">
          <div className="tracks">
            <div className="track">
              <h3>1. Student Track</h3>
              <p><strong>College and university students.</strong> Get the practical guidance and advice you need from experienced security leaders to dive into the security field with a clear plan in mind.</p>
            </div>
            <div className="track">
              <h3>2. Individual Contributor</h3>
              <p><strong>Entry-level or focused roles.</strong> Get the support you need to kickstart your security career in any entry-level position or focused role like pentesting or risk analysis.</p>
            </div>
            <div className="track">
              <h3>3. Management Track</h3>
              <p><strong>Director and leadership roles.</strong> Get ready for your next leadership position from management to director roles in the security field.</p>
            </div>
            <div className="track">
              <h3>4. Executive Track</h3>
              <p><strong>Senior leadership and CISO roles.</strong> Get your senior executive career started right with a focus on nailing your next Head of Security or CISO role.</p>
              <div className="linkWrap">
                <HashLink smooth to="/#subscribe">Get started</HashLink>
              </div>
            </div>
          </div>
        </div>
        <h2>Good for you. Good for business.</h2>
        <div className="business">
          <img src={illustrationPeople} alt="Illustration of a man and a woman lifting a pie chart upwards together" title="Build your people." />
          <div className="businessWrap">
            <h3>Build your people.</h3>
            <p><strong>Invest in your security team.</strong>  Give your employees the support they need to rock their role. Get a mentor for your team to align security with your company's goals.</p>
            <div className="linkWrap">
              <HashLink smooth to="/corporate">Learn more</HashLink>
            </div>
          </div>
        </div>
      </div>
      <div className="calculator" id="subscribe">
        <h2>Plans designed by you.</h2>
        <p className="tag">Affordable options for every stage of your career.</p>
        <div className="calculatorWrap">
          <form onSubmit={e => { e.preventDefault() }} id="signup-form" className="signupform calc">
            <div className="optionWrap">
              <label className="emailWrap captcha">
                <h3>What is this captcha?</h3>
                <input onChange={captchaOnChange} type="email" className="email" placeholder="" />
              </label>
              <h3>Choose your career track.</h3>
              <p>Which career track are you on now?</p>
              <Dropdown title="Pick your track" list={dropdowns.careerList} resetThenSet={resetThenSet} tooltipMessage="Select your career track" showTooltip={showDropdownTooltip.careerList} />
              <h3>Choose your timeline.</h3>
              <p>How much time do you want each month?</p>
              <Dropdown title="Pick your hours" list={dropdowns.hoursList} resetThenSet={resetThenSet} tooltipMessage="Select your number of hours" showTooltip={showDropdownTooltip.hoursList} />
              <label className="emailWrap">
                <h3>What is your email?</h3>
                <p>Enter your email address to get started.</p>
                <input onBlur={emailOnBlur} onChange={emailOnChange} type="email" className="email" placeholder="" required />
                <div className={ showEmailTooltip ? "emailTooltip" : "emailTooltip hidden" }>
                  <p>Enter a valid email address</p>
                </div>
              </label>
              <label className="privacyWrap">
                <input onChange={privacyConsentOnChange} defaultChecked={privacyConsent} type="checkbox" className="privacy" />
                <span className="privacyCheckbox"></span>
                <p className="privacyLabel">I accept the terms of the <a href="/privacy">privacy policy</a>.</p>
              </label>
              <div className="mobileCta">
                <p className="sub">Click the button below to connect with your mentor today.</p>
                <p className="sub terms">By submitting this form, you agree to the <Link to="/terms">terms and conditions</Link>.</p>
                <div className="linkWrap">
                  <a href="#subscribe-button" onClick={formHandler} title="Click here to get your mentor" className="subscribeButton">Get your Mentor</a>
                  <div className={`formSubmitOverlay ${ formTriggered ? "open" : "" } ${ formError ? "error" : "" }`}>
                    <p className="loading mobile">Loading<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="loading wide">Texting your mentor<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="errorMessage">Oops, an error occurred!</p>
                    <p className="errorMessage sub">Please refresh the page and try again.</p>
                    <p className="errorMessage sub small">(We'll automatically refresh this page in {refreshIn}...)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="priceWrap">
              <div className="price">
                <h4>{price.tag}</h4>
                <p className="total"><span className="currency">$</span>{price.total}<span className="frequency"><span>/month</span><span className="offer">after 30 day free trial</span></span></p>
                <p className="sub mobile">Fill out the form to build your plan.</p>
                <p className={ price.isStudent ? "sub strong" : "sub hidden"}>You need a valid school-issued ID or email address to sign up for the Student Track.</p>
                <p className="sub wide">Click the button below to connect with your mentor today.</p>
                <p className="sub wide terms">By submitting this form, you agree to the <Link to="/terms" target="_blank">terms and conditions</Link>.</p>
                <div className="sub wide linkWrap">
                  <a href="#subscribe-button" onClick={formHandler} title="Click here to get your mentor" className="subscribeButton">Get your Mentor</a>
                  <div className={`formSubmitOverlay ${ formTriggered ? "open" : ""} ${ formError ? "error" : ""}`}>
                    <p className="loading mobile">Loading<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="loading wide">Texting your mentor<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="errorMessage">Oops, an error occurred!</p>
                    <p className="errorMessage sub">Please refresh the page and try again.</p>
                    <p className="errorMessage sub small">(We'll automatically refresh this page in {refreshIn}...)</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default Home
