import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink } from 'react-router-hash-link'
import { Analytics } from '../../context/Analytics'
import { Consent } from '../../context/Consent'
import './styles.css'

const Privacy = () => {
  const analytics = Analytics()
  const consent = Consent()

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return <div className="content">
    <Helmet>
      <title>CISO.DEV - Privacy Policy</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <div className="policy">
      <h1 id="privacy-policy">Privacy Policy</h1>
      <p id="date" className="date">Last updated: 2020-01-09</p>
      <p id="p1">This policy explains how we collect, use and share your personal information that we obtain through your use of our website and services (the "Site").</p>
      <p id="p2">We will only process your personal information in accordance with applicable data protection and privacy laws.</p>
      <h2 id="information-collected">1. What information do we collect?</h2>
      <p id="p3">We only ask for personal information when we truly need it to provide a service to you.  We collect it by fair and lawful means, with your knowledge and consent.  We also let you know why we're collecting it and how it will be used.</p>
      <h3 id="personal-information">Person information you share with us</h3>
      <p id="p4">We collect <em>personal information</em> that you voluntarily provide to us such as your name, contact information, and payment information (if you choose to contact us or subscribe to the Site).</p>
      <p id="p5">The personal information that we collect depends on the context of your interactions with eCISO, LLC and the Site.  Here's what we generally collect:</p>
      <p id="name-and-contact"><strong className="label">Name and Contact Details:</strong> When you choose to contact us or subscribe to the Site, we collect details including your first and last name, email address, and other similar contact information required to process your request.</p>
      <p id="payment-info"><strong className="label">Payment Information:</strong> If you choose to subscribe to the Site, we collect the data necessary to process your payment such as your payment instrument number (e.g., credit card number), and the security code associated with your payment instrument.  All payment data is securely stored by our third-party payment processor, <a href="https://stripe.com/privacy" title="Stripe, Inc. Privacy Policy">Stripe, Inc.</a>; you should review their privacy policies and contact the payment processor directly to respond to your questions.</p>
      <p id="demo-and-resume"><strong className="label">Demographic and Resume Data:</strong> When you subscribe to the Site or apply to a job with eCISO, LLC., we collect details including your employment history, age, gender, country, writing samples and references.</p>
      <p id="survey-testimonial"><strong className="label">Survey and Testimonial Data:</strong> If you participate in any surveys or tesimonial requests conducted by or on behalf of eCISO, LLC., we may collect data regarding your preferences, opinions, and feedback on how we can improve the Site.  Response to our surveys is entirely voluntary.</p>
      <p id="p6">All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information as necessary.</p>
      <h3 id="automatic-collection">Information collected automatically</h3>
      <p id="p7">Some information, such as your IP address, browser and device characteristics, device operating system, language preferences, and other details may automatically be shared with us by your web browser when you access the Site.  This data may also include information such as your device name, referring URLs, country, location, statistics about how and when you use the Site, and other technical details about the device you are accessing the Site from.  This information is primarily needed to maintain the security and operation of the Site, and for our internal analytics and reporting purposes.</p>
      <h3 id="other-sources">Information collected from other sources</h3>
      <p id="p8">We like that you are interested in the Site, but we really aren't that interested in your personal life (no offense intended).  eCISO, LLC does not, for the purposes of the Site and with respect to this privacy policy, collect personal information about you from other sources such as public databses, joint marketing partners, or other related third parties.</p>
      <p id="p9">Our Site may link to external websites that are not operated by us.  Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
      <h2 id="how-we-use-your-information">2. How do we use your information?</h2>
      <p id="p10">We use personal information collected through the Site for a variety of business purposes based on legitimate business interests, the fulfillment of any binding agreement with you, compliance with our legal obligations, and your explicit consent.</p>
      <p id="p11">In addition to the purposes described elsewhere in this policy, the information we collect or receive is used to:</p>
      <p id="p12"><strong className="label">Protect the Site:</strong> Your information may be used as part of our efforts to operate the Site safely and securely (for example, for fraud monitoring and prevention).</p>
      <p id="p13"><strong className="label">Enforce our Policies:</strong> In the event that our policies may be violated, we may use your information to investigate or enforce any aspect of those policies.</p>
      <p id="p14"><strong className="label">Provide Customer Support:</strong> We may use information shared with us to respond to your questions, complaints, or comments regarding the Site or other services which we provide.</p>
      <p id="p15"><strong className="label">Fulfill a Valid Contract:</strong> Where we have engaged in a legally binding contract with you, we may use your information to provide services as assigned by that agreement.</p>
      <p id="p15"><strong className="label">Comply with Law Enforcement:</strong> If we receive a subpoena or other valid legal request, we may need to share or inspect the data in our possession to determine how to respond appropriately.</p>
      <p id="p17"><strong className="label">Support our Business:</strong> We may use your information to evaluate and improve the content or quality of the Site, conduct data analysis, and identify anonymized usage trends.</p>
      <h2 id="will-information-be-shared">3. Will your information be shared?</h2>
      <p id="p18">We don't share any personally identifying information publicly or with third-parties, except when required to by law, to protect your rights, to fulfill our obligations, or with your explcit consent.</p>
      <p id="p19">To that extent, eCISO, LLC may process or share your data based on the following legal bases:</p>
      <p id="your-consent"><strong className="label">Your Consent:</strong> We may process your data if you have given us clear and explicit consent to use your personal information for a specific purpose.</p>
      <p id="legitimate-interests"><strong className="label">Legitimate Interests:</strong> We may process your personal information when it is reasonably necessary to acheive our legitimate business interests.</p>
      <p id="contract-performance"><strong className="label">Performance of a Contract:</strong> We may process your personal information to fulfill the terms of any contract eCISO, LLC has entered into with you.</p>
      <p id="legal-obligations"><strong className="label">Legal Obligations:</strong> We may process or disclose your information where we are legally required to do so to comply with a valid legal requirement.</p>
      <p id="vital-interests"><strong className="label">Vital Interests</strong> We may disclose your information where we believe it is necessary to protect the vital interests of the Site, eCISO, LLC, or other parties.</p>
      <p id="p20">We only share and disclose your information with the following parties.</p>
      <ul>
        <h3 id="functionality-and-infrastructure">Functionality and Infrastructure</h3>
        <li id="p21">Amazon Web Services - <a href="https://aws.amazon.com/privacy/" title="AWS Privacy Policy">Privacy Policy</a></li>
      </ul>
      <ul>
        <h3 id="web-mobile-analytics">Web and Mobile Analytics</h3>
        <li id="p22">Google Analytics - <a href="https://policies.google.com/privacy" title="Google Privacy Policy">Privacy Policy</a></li>
        <li id="p23">HubSpot - <a href="https://legal.hubspot.com/privacy-policy" title="HubSpot Privacy Policy">Privacy Policy</a></li>
        <li id="p24">Netlify - <a href="https://www.netlify.com/privacy/" title="Netlify Privacy Policy">Privacy Policy</a></li>
      </ul>
      <ul>
        <h3 id="website-hosting">Website Hosting</h3>
        <li id="p25">Amazon Web Services - <a href="https://aws.amazon.com/privacy/" title="AWS Privacy Policy">Privacy Policy</a></li>
        <li id="p26">Netlify - <a href="https://www.netlify.com/privacy/" title="Netlify Privacy Policy">Privacy Policy</a></li>
      </ul>
      <ul>
        <h3 id="payment-processors">Payment Processing</h3>
        <li id="p27">Stripe - <a href="https://stripe.com/privacy" title="Stripe Privacy Policy">Privacy Policy</a></li>
      </ul>
      <ul>
        <h3 id="user-email-contact">User Email Contact</h3>
        <li id="p28">Amazon Web Services - <a href="https://aws.amazon.com/privacy/" title="AWS Privacy Policy">Privacy Policy</a></li>
        <li id="p29">Google - <a href="https://policies.google.com/privacy" title="Google Privacy Policy">Privacy Policy</a></li>
        <li id="p30">Netlify - <a href="https://www.netlify.com/privacy/" title="Netlify Privacy Policy">Privacy Policy</a></li>
      </ul>
      <p id="p31">If we have processed your data based on your consent and you wish to revoke your consent, please contact us at <a href="mailto:privacy@ciso.dev" title="Contact us at privacy@ciso.dev for assistance">privacy@ciso.dev</a> for assistance.</p>
      <h2 id="do-we-use-cookies">4. Do we use cookies and other tracking technologies?</h2>
      <p id="p32">Yes - We may use cookies and similar tracking technologies (like web beacons and tracking pixels) to access or store information.  All cookies are disabled until you explicitly opt-in to each service, unless they are required to deliver the Site or required functionality to you.</p>
      <p id="p33">You can manage your cookie preferences on the Site at any time by clicking on the <a href="#cookie-preferences" onClick={consent.toggleNotice} title="Manage your cookies">cookies</a> link at the bottom of each page.</p>
      <h2 id="how-long-do-we-keep-information">5. How long do we keep your information?</h2>
      <p id="p34">We only retain collected information for as long as necessary to provide you with your requested service.  In general, anonymized data used for business analytics (such as that stored in Google Analytics) is retained for approximately 14 months.  Unless a longer retention period is required by law (suc as tax, accounting or other legal requirements) or to fulfill a valid contract, we will not retain your information for longer than 2 years.</p>
      <h2 id="how-do-we-protect-information">6. How do we keep your information safe?</h2>
      <p id="p35">What data we do store, we are committed to protecting within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.  eCISO, LLC has implemented appropriate technical and organizational security measures designed to protect and validate the security, confidentiality and integrity of any personal information we process.</p>
      <p id="p36">Please remember that we cannot guarantee that the internet or any technical resources are 100% secure; Although we do our best to protect your personal information, transmission of such data to and from the Site is at your own risk.</p>
      <h2 id="information-regarding-children">7. Do we collect information from children?</h2>
      <p id="p37">No, eCISO, LLC does not knowingly solicit data from or market to children under 18 years of age.  By using the Site, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Site.  If we learn that personal information from users less than 18 years of age have been collected, we will take reasonable measures to promptly delete such data from our records.</p>
      <p id="p38">If you become aware of any data we have collected from children under the age of 18, please contact us immediately at <a href="mailto:privacy@ciso.dev" title="Contact us at privacy@ciso.dev for assistance">privacy@ciso.dev</a>.</p>
      <h2 id="your-privacy-rights">8. What are your privacy rights?</h2>
      <p id="p39">The Site is not designed or intended to collect significant amounts of personal information, however we recognize the importance of upholding your rights to maintaining the privacy of your data.  You have the right to review, change, or permanently erase any of your personal information disclosed to eCISO, LLC through your use of the Site at any time.  We will respect these rights regardless of where you reside.  You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some or all of your desired services.</p>
      <p id="p40">Please refer to your local privacy regulations and legislature - You may have additional rights or responsibilities with regards to your privacy dependent upon your locale.</p>
      <h3 id="european-economic-area">Notice for residents of the European Economic Area</h3>
      <p id="p41">If you are a resident of the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain about us to your local data protection supervisory authority (<a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" title="Data protection supervisory authorities">find their contact details here</a>).</p>
      <h3 id="california-privacy-rights">Notice for residents of California</h3>
      <p id="p42">In accordance to the California Consumer Privacy Act of 2018 (the "CCPA"), residents of California have additional rights to know, delete and opt-out of data processing activities by eCISO, LLC., including a requirement for businesses collecting or disclosing personal information to provide notices and means to exercise those rights.  The terms in this section have the definitions given to them in the CCPA, which may be broader than their common meanings.  For example, the definition of "personal information" under the CCPA includes your name, but also more general information such as your age.</p>
      <h4 id="notice-of-collection">Notice of Collection</h4>
      <p id="p43">Although the information we collect is described in greater detail in sections 1 - 7 above, the categories of personal information that we have collected as described by the CCPA in the past 12 months are:</p>
      <ul className="list">
        <li>Identifiers, including name, email address, phone number, account name, IP address, and cryptographically unique ID or number assigned to your account.</li>
        <li>Customer records, billing address, account payable information, and credit or debit card information.</li>
        <li>Demographics such as your age or gender.  This category includes data that may qualify as protected classifications under other California or federal laws.</li>
        <li>Commercial information including purchases and engagement with the Site.</li>
        <li>Internet activity, including your interactions with our Site.</li>
        <li>Employment and education data, including information you provide when you subscribe or apply for a job with us.</li>
        <li>Inferences, including information about your interests, preferences and favorites.</li>
      </ul>
      <p id="p44">For more information on our collection practices, including the sources we receive information from, please review the details listed in Sections 1 - 7 above.  We collect and use these categories of personal information for the business purposes also described in Sections 1 - 7, as well as our sharing practices described in Section 3.</p>
      <p id="p45">We do not sell personal information as the term "sale" under the CCPA is interpreted.</p>
      <h4 id="right-to-know-and-delete">Right to Know and Delete</h4>
      <p id="p46">If you are a California resident, you have the right to delete the personal information we have collected from you and the right to know certain information about our data practices in the preceding 12 months.  In particular, you have the right to request the following from us:</p>
      <ul className="list">
        <li>The categories of personal information we have collected about you</li>
        <li>The categories of sources from which the personal information was collected</li>
        <li>The categories of personal information about you we disclosed for a business purpose or sold</li>
        <li>The categories of third parties to whom the personal information was disclosed for a business purpose or sold</li>
        <li>The business or commercial purpose for colelcting or selling the personal information</li>
        <li>The specific pieces of personal information we have collected about you</li>
      </ul>
      <p id="p47">To exercise any of these rights, please email us at <a href="mailto:privacy@ciso.dev" title="Contact us at privacy@ciso.dev for assistance">privacy@ciso.dev</a>.  In the request, please specify which right you are seeking to exercise and the scope of the request.  We will confirm receipt of your request within 10 days.</p>
      <p id="p48">As a holder of certain personal information, we have a duty to verify your identity when making requests to know or delete personal information and to ensure that dissemination of that information would not cause harm to you if it were distributed to another person.</p>
      <p id="p48-1">To verify your identity, we will request and collect additional personal information from you to match it against our records.  We may ask for additional information or documentation if we feel it is necessary to confirm your identity with the necessary degree of certainty.  We may communicate with you through email, a secure message center, or other reasonably necessary and appropriate means.  We do have the right to deny requests under certain circumstances.  In such cases, we will notify you of the reasons for denial.</p>
      <p id="p48-2">We will not provide you with specific pieces of personal information if the disclosure creates a substantial, articulable, and unreasonable risk to the security of that personal information, your account with us, or the security of our systems or networks.  In no event will we disclose, if we have collected it, your Social Security number, driver's license number or other government-issued identification number, financial account number, any health insurance or medical identification number, an account password, or security questions and answers.</p>
      <h4 id="right-to-opt-out">Right to Opt-Out</h4>
      <p id="p49">We do not sell your personal information as the term "sale" is defined under the California Consumer Privacy Act.  If you have questions about this, please contact us at <a href="mailto:privacy@ciso.dev" title="Contact us at privacy@ciso.dev for assistance">privacy@ciso.dev</a> for assistance.</p>
      <h4 id="authorized-agent">Authorized Agent</h4>
      <p id="p50">You may submit a request through a designated agent.  You must instruct that agent that they will need to state that they are acting on your behalf when making the request, have reasonably necessary documentation, and be prepared to provide the necessary personal information to identify you in our records.</p>
      <h4 id="right-to-non-discrimination">Right to Non-Discrimination</h4>
      <p id="p51">You have the right not to receive discriminatory treatment by us for the exercise of any of your rights.</p>
      <h4 id="financial-incentives">Financial Incentives</h4>
      <p id="p52">Financial incentives are programs, benefits, or other offerings, including payments to consumers as compensation, for the disclosure, deletion, or sale of personal information about them.</p>
      <p id="p53">We may offer discounted prices to consumers who sign up to be on our mailing lists, join our loyalty programs, or participate in surveys and other research activities.  Such programs will have additional terms that require your review and agreement.  Please review those terms for the details of those programs, how to withdraw or cancel, or to assert your rights specific to those programs.</p>
      <p id="p54">We generally do not treat consumers differently if they exercise a right under California law, however, in certain circumstances, discounted prices will require you to be on our mailing list, a member of our loyalty program, or to have participated in certain research or survey activities.  In such circumstances, we may offer a price difference because the price is reasonably related to the value of your data.  The value of your data will be explained in the terms of such incentivized programs.</p>
      <h4 id="shine-the-light">Shine the Light</h4>
      <p id="p55">California's "Shine the Light" law permits customers in California to request certain details about how certain types of their information are shared with third parties and, in some cases, affiliates, for those third parties' and affiliates' own direct marketing purposes.  Under the law, a business should either provide California customers certain information upon request or permit California customers to opt out of this type of sharing.</p>
      <p id="p56">To exercise a Shine the Light request, please contact us at <a href="mailto:privacy@ciso.dev" title="Contact us at privacy@ciso.dev for assistance">privacy@ciso.dev</a> for assistance.  You must put the statement, "Your California Privacy Rights" in the body of your email, as well as your name, street address, city, state, and zip code.  Please provide enough information in the body of your request for us to determine if this applies to you.  Please note that we will not accept inquiries via the telephone or facsimile, and we are not responsible for notices that are not labelled or sent properly, or that do not have complete information.</p>
      <h2 id="policy-changes">9. Do we make updates to this policy?</h2>
      <p id="p57">This policy is updated from time to time to reflect our current operating procedures.  We encourage you to regularly review this privacy policy to stay informed of how we are protecting your information.</p>
      <h2 id="privacy-contact">10. How can you contact us about this policy?</h2>
      <p id="p58">If you have any questions, comments or concerns about this policy, or would like to exercise your rights, please reach out to us by email at <a href="mailto:privacy@ciso.dev" title="Contact us at privacy@ciso.dev for assistance">privacy@ciso.dev</a> for assistance.</p>
    </div>
  </div>
}

export default Privacy
