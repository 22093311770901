import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Corporate from './pages/Corporate'
import Careers from './pages/Careers'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import SuccessPage from './pages/Success'
import FourOhFour from './pages/404'
//import Login from './pages/Login'
//import Verify from './pages/Verify'
import './App.css'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Helmet>
            <title>CISO.DEV - Level up your security career</title>
            <meta name="description" content="Security mentors for every step of your cyber security career." />
            <meta name="keywords" content="mentor as a service,find a security mentor,security advisor,security mentor,mentor,cyber,professional development,get a mentor,find a mentor in security,security,infosec,mentor benefits,security career,career growth,CISO,leadership development,eCISO,security for everyone" />
          </Helmet>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/success" component={SuccessPage} />
            <Route path="/corporate" component={Corporate} />
            <Route path="/careers" component={Careers} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route component={FourOhFour} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}

export default App;
