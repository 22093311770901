import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import './styles.css'

class Header extends Component{
  constructor(props){
    super(props)
    this.state = {
      menuOpen: false,
      location: this.props.location,
    }
    this.close = this.close.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  componentDidUpdate() {
    const { menuOpen } = this.state
    setTimeout(() => {
      if (menuOpen) {
        window.addEventListener('click', this.close)
      } else {
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close)
  }

  close(timeOut) {
    this.setState({
      menuOpen: false
    })
  }

  handleMenuClick() {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
    }))
  }

  render() {
    return <header className="header" id="header">
      <div className="logo-wrapper">
        <div className={ "hamburger-menu" + (this.state.menuOpen ? " open" : " closed" )} onClick={this.handleMenuClick}>
          <div className="menu-check bars-container" id="menu-check">
            <span className="bar bar1"></span>
            <span className="bar bar2"></span>
            <span className="bar bar3"></span>
          </div>
        </div>
        <Link to="/" onClick={this.close} title="CISO.DEV" className="logo">CISO.DEV</Link>
      </div>
      <div className="cta-wrapper">
        <Link onClick={this.close} to="/careers" title="Apply to become a mentor" className="secondary"><span>Be a Mentor</span></Link>
        <HashLink onClick={this.close} smooth to="/#subscribe" title="Get a mentor" className="primary"><span>Get a Mentor</span></HashLink>
      </div>
      <div className="nav-background"></div>
      <div className={"menu" + (this.state.menuOpen ? " open" : " closed" )}>
        <ul className="nav">
          <li><a onClick={this.close} href="https://app.ciso.dev/login" title="Sign in to your account">Sign In</a></li>
          <li><HashLink smooth onClick={this.close} to="/#subscribe" title="Sign up for mentoring">Get a Mentor</HashLink></li>
          <li><Link onClick={this.close} to="/careers" title="Apply to become a mentor">Become a Mentor</Link></li>
          <li className="hidden"><Link onClick={this.close} to="/about" title="About our services">About Us</Link></li>
          <li><Link onClick={this.close} to="/corporate" title="Get a mentor for your business" className="cta">Corporate Programs</Link></li>
        </ul>
      </div>
    </header>
  }
}

export default Header
