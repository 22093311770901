import React, { useState, useEffect } from 'react'

const ThemeContext = React.createContext()

const ThemeProvider = (props) => {
  const [showHeader, setShowHeader] = useState(true)
  const [showFooter, setShowFooter] = useState(true)

  const hideHeader = () => {
    setShowHeader(false)
  }

  const displayHeader = () => {
    setShowHeader(true)
  }

  const hideFooter = () => {
    setShowFooter(false)
  }

  const displayFooter = () => {
    setShowFooter(true)
  }

  return <ThemeContext.Provider value={{showHeader, displayHeader, hideHeader, showFooter, displayFooter, hideFooter}} {...props} />
}

const Theme = () => {
  const context = React.useContext(ThemeContext)
  if (typeof context === 'undefined') {
    throw new Error('Theme must be used within a ThemeProvider')
  }
  return context
}

export { ThemeProvider, Theme }
