import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Analytics } from '../../context/Analytics'
import emailIsValid from '../../components/EmailValidator'
import './styles.css'

const axios = require('axios')

const Careers = (props) => {
  const analytics = Analytics()

  const [captcha, setCaptcha] = useState('')
  const [privacyConsent, setPrivacyConsent] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [showEmailTooltip, setShowEmailTooltip] = useState(false)
  const [formTriggered, setFormTriggered] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formError, setFormError] = useState(false)
  const [refreshIn, setRefreshIn] = useState(5)

  let timer
  let refreshTimer = {
    time: {},
    seconds: 5,
  }

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60))
    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)
    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)
    let obj = {
      'h': hours,
      'm': minutes,
      's': seconds,
    }
    return obj
  }

  const refreshCountdown = () => {
    const seconds = refreshTimer.seconds - 1
    if (seconds === 0) {
      clearInterval(timer)
      window.location.reload()
    }
    refreshTimer = {
      time: secondsToTime(seconds),
      seconds: seconds,
    }
    setRefreshIn(seconds)
  }

  const refreshTimerStart = () => {
    timer = setInterval(refreshCountdown, 1000)
  }

  const captchaOnChange = (e) => {
    setCaptcha(e.target.value)
  }

  const privacyConsentOnChange = () => {
    setPrivacyConsent(!privacyConsent)
  }

  const emailOnBlur = () => {
    if (emailValid && emailAddress !== '') {
      setShowEmailTooltip(false)
    } else {
      setShowEmailTooltip(true)
    }
  }

  const emailOnChange = (e) => {
    setEmailAddress(e.target.value)
    if (e.target.value.length < 7) {
      // Too short to be an email address
      return
    }
    if (emailIsValid(e.target.value)) {
      setEmailValid(true)
      setShowEmailTooltip(false)
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE'
    } else {
      setEmailValid(false)
      e.target.style.borderColor = '#dfdfdf'
    }
  }

  const formHandler = (e) => {
    e.preventDefault()
    // Check if required fields are filled out
    if (!emailValid) {
      setShowEmailTooltip(true)
      return
    }
    setFormTriggered(!formTriggered)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'form submit',
    })
    // Check captcha
    if (captcha !== '') {
      setFormError('Invalid captcha provided!')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `Invalid captcha provided`,
        type: `error`,
      })
      refreshTimerStart()
      return
    }
    // Post to API
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/v1/apply`, {
        captcha: captcha,
        email: emailAddress,
        consent: privacyConsent
      }).then((res) => {
        // Show success modal
        setFormSuccess(true)
        refreshTimerStart()
      }).then((res) => {
      }).catch((err) => {
        setFormError('Error connecting to API!')
        analytics.startSingleEvent({
          href: window.location.href,
          msg: `${err.message}`,
          type: `error`,
        })
        refreshTimerStart()
      })
    } catch (err) {
      setFormError('An error occurred')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `${err.message}`,
        type: `error`,
      })
      refreshTimerStart()
    }
  }

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return <div className="content">
    <Helmet>
      <title>CISO.DEV - Apply to become a security mentor</title>
      <meta name="description" content="CISO.DEV offers security mentors as a service. Apply now to become a security mentor to other security professionals today." />
      <meta name="keywords" content="mentor as a service,careers,professional development,job application,security mentor,become a security mentor,start mentoring,be a mentor,cyber security,career growth,CISO,leadership development,mentoring jobs" />
    </Helmet>
    <div className="careers">
      <div className="calculator" id="subscribe">
        <h2>Become a mentor.</h2>
        <p className="tag">Apply here to join the waiting list.</p>
        <div className="calculatorWrap">
          <form onSubmit={e => { e.preventDefault() }} id="signup-form" className="signupform calc">
            <div className="optionWrap">
              <label className="emailWrap captcha">
                <h3>What is this captcha?</h3>
                <input onChange={captchaOnChange} type="email" className="email" placeholder="" />
              </label>
              <label className="emailWrap">
                <h3>What is your email?</h3>
                <p>Enter your preferred email address.</p>
                <input onBlur={emailOnBlur} onChange={emailOnChange} type="email" className="email" placeholder="" required />
                <div className={ showEmailTooltip ? "emailTooltip" : "emailTooltip hidden" }>
                  <p>Enter a valid email address</p>
                </div>
              </label>
              <label className="privacyWrap">
                <input onChange={privacyConsentOnChange} defaultChecked={privacyConsent} type="checkbox" className="privacy" />
                <span className="privacyCheckbox"></span>
                <p className="privacyLabel">I accept the terms of the <a href="/privacy">privacy policy</a>.</p>
              </label>
              <div className="mobileCta">
                <p className="sub">Click the button below to join the waiting list.</p>
                <p className="sub terms">By submitting this form, you agree to the <Link to="/terms">terms and conditions</Link>.</p>
                <div className="linkWrap">
                  <a href="#subscribe-button" onClick={formHandler} title="Click here to apply to be a mentor" className="subscribeButton">Get your Mentor</a>
                  <div className={`formSubmitOverlay ${ formTriggered ? "open" : "" } ${ formError ? "error" : "" } ${formSuccess ? "success" : "" }`}>
                    <p className="loading mobile">Loading<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="loading wide">Joining the waitlist<span className="dotWrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
                    <p className="errorMessage">Oops, an error occurred!</p>
                    <p className="errorMessage sub">Please refresh the page and try again.</p>
                    <p className="errorMessage sub small">(We'll automatically refresh this page in {refreshIn}...)</p>
                    <p className="successMessage">Got it, thanks for applying!</p>
                    <p className="successMessage sub">We'll contact you when the waitlist opens.</p>
                    <p className="successMessage sub small">(This page will automatically refresh in {refreshIn}...)</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default Careers
