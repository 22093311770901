import React from 'react'
import { Consent } from '../../context/Consent'
import './styles.css'

const ConsentManager = (props) => {
  const consent = Consent()

  return <div id="cookie-consent" className="cookieConsent">
    <div className={ consent.noticeOpen ? "notice open" : "notice" } id="cookie-notice">
      <div className="body">
        <p>We use cookies for: Analytics, Payment Processing. <a href="#cookie-preferences" title="Manage your cookie preferences" onClick={consent.toggleModal}>Learn more...</a></p>
        <p className="options">
          <button onClick={consent.consentAcceptHandler} className="accept" type="button">Accept</button>
          <button onClick={consent.consentDeclineHandler} className="decline" type="button">Decline</button>
        </p>
      </div>
    </div>
    <div className={ consent.modalOpen ? "modal open" : "modal" }>
      <div onClick={consent.toggleModal} className="background"></div>
      <div className="window">
        <div className="header">
          <button onClick={consent.toggleModal} title="Close" className="hide" type="button">
            <svg role="img" aria-label="Close" width="12" height="12" viewport="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <title>Close</title>
              <line x1="1" y1="11" x2="11" y2="1" strokeWidth="1"></line>
              <line x1="1" y1="1" x2="11" y2="11" strokeWidth="1"></line>
            </svg>
          </button>
          <h1 className="title">Information that we collect</h1>
          <p>Here you can see and customize the information that we collect about you. To learn more, please read our <a href="/privacy" title="Read our privacy policy">privacy policy</a>.</p>
        </div>
        <div className="body">
          <ul className="apps">
            <li className="app">
              <div>
                <input id="appCookies" className="input" aria-describedby="appcookie-description" disabled={true} type="checkbox" />
                <label htmlFor="appCookies" className="label" tabIndex="0">
                  <span className="appTitle">Authentication Cookies</span>
                  <span className="required" title="This application is always required">(always required)</span>
                  <span className="switch disabled">
                    <div className="slider active">
                    </div>
                  </span>
                </label>
                <div className="descriptionWrapper" id="appCookiesDescription">
                  <p className="description">Functional cookies used for managing application authentication.</p>
                  <p className="purposes">Purpose: Functional</p>
                </div>
              </div>
            </li>
            <li className="app">
              <div>
                <input id="stripeCheckout" className="input" aria-describedby="stripe-description" disabled={true} type="checkbox" />
                <label htmlFor="stripeCheckout" className="label" tabIndex="0">
                  <span className="appTitle">Stripe Checkout</span>
                  <span className="required" title="This application is always required">(always required)</span>
                  <span className="switch disabled">
                    <div className="slider active">
                    </div>
                  </span>
                </label>
                <div className="descriptionWrapper" id="stripeCheckoutDescription">
                  <p className="description">Functional cookies used for payment processing through Stripe Checkout.</p>
                  <p className="purposes">Purpose: Functional, Payment Processing</p>
                </div>
              </div>
            </li>
            <li className="app toggleAll">
              <div>
                <input id="disableAll" className="input" aria-describedby="disableAll-description" type="checkbox" />
                <label htmlFor="disableAll" className="label">
                  <span className="appTitle">Toggle all apps</span>
                  <span className="switch">
                    <div className="slider"></div>
                  </span>
                </label>
                <div className="descriptionWrapper" id="disableAllDescription">
                  <p className="description">Use this switch to enable/disable all apps</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer">
          <button onClick={consent.toggleModal} className="button" type="button">Save</button>
          <a href="https://eciso.io" target="_blank" rel="noopener noreferrer" className="poweredBy" title="Provided by eCISO">Powered by eCISO</a>
        </div>
      </div>
    </div>
  </div>
}

export default ConsentManager
