import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Consent } from '../../context/Consent'
import './styles.css'

const Footer = (props) => {
  const consent = Consent()

  return <footer className="footer" id="footer">
    <div className="footer-nav">
      <div className="logo">
        <Link to="/" title="CISO.DEV">CISO.DEV</Link>
        <p className="slogan">Level up your security career.</p>
      </div>
      <div className="nav">
        <a href="https://app.ciso.dev/login" title="Sign in to your account">Sign In</a>
        <HashLink smooth to="/#subscribe" title="Sign up to get your own mentor today">Get a Mentor</HashLink>
        <Link to="/careers" title="Apply to become a mentor">Be a Mentor</Link>
        <Link to="/corporate" title="Learn more about our corporate mentors program">Corporate Mentors</Link>
      </div>
    </div>
    <p className="copyright">&copy; eciso, llc. 2019 &bull; <a href="#cookies" onClick={consent.toggleNotice} id="cookie-preferences" title="Manage your cookie preferences" className="cookie-button">cookies</a> &bull; <Link to="/terms" title="Read our Terms of Service">terms</Link> &bull; <Link to="/privacy" title="Read our Privacy Policy">privacy</Link></p>
  </footer>
}

export default Footer
