import React from 'react'
import { AnalyticsProvider } from './Analytics'
//import { AuthProvider } from './Auth'
import { ThemeProvider } from './Theme'
import { ConsentProvider } from './Consent'

const AppProviders = ({children}) => {
  /*
  return <AnalyticsProvider>
      <AuthProvider>
        <ThemeProvider>
          {children}
        </ThemeProvider>
      </AuthProvider>
    </ConsentProvider>
  </AnalyticsProvider>
  */
  return <AnalyticsProvider>
    <ConsentProvider>
        <ThemeProvider>
          {children}
        </ThemeProvider>
    </ConsentProvider>
  </AnalyticsProvider>
}

export default AppProviders
