import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Analytics } from '../../context/Analytics'
import './styles.css'

const illustrationBob = require('../../images/bob.png')

const FourOhFour = () => {
  const analytics = Analytics()

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'error',
      msg: '404',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return <div className="content full">
    <Helmet>
      <title>404: CISO.DEV - Level up your security career</title>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
    </Helmet>
    <div className="fourOhFour">
      <div className="messageWrap">
        <h1>404: That's an error, Bob.</h1>
        <img src={illustrationBob} alt="Illustration of a man with large fluffy hair painting the numbers 404 into a landscape" title="That's an error, Bob." />
        <p>We couldn't find that page in our happy trees.</p>
        <p className="sub"><a href="#go-back" title="Go back and try again" onClick={e => { e.preventDefault(); window.history.go(-1); return false; }}>Go back</a> and try again?</p>
      </div>
    </div>
  </div>
}

export default FourOhFour
