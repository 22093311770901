import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import './styles.css'

class Dropdown extends Component{
  constructor(props){
    super(props)
    this.state = {
      listOpen: false,
      headerTitle: this.props.title
    }
    this.close = this.close.bind(this)
  }

  componentDidUpdate(){
    const { listOpen } = this.state
    setTimeout(() => {
      if(listOpen){
        window.addEventListener('click', this.close)
      }
      else{
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }

  componentWillUnmount(){
    window.removeEventListener('click', this.close)
  }

  close(timeOut){
    this.setState({
      listOpen: false
    })
  }

  selectItem(title, id, stateKey){
    this.setState({
      headerTitle: title,
      listOpen: false
    }, this.props.resetThenSet(id, stateKey))
  }

  toggleList(){
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }))
  }

  render(){
    const{list} = this.props
    const{listOpen, headerTitle} = this.state
    return(
      <div id={this.props.id ? this.props.id : ""} className={`${this.state.listOpen ? "dd-wrapper dd-open" : "dd-wrapper"} ${this.props.className}`}>
        <div className={ this.state.headerTitle === this.props.title ? "dd-header" : "dd-header dd-header-selection" } onClick={() => this.toggleList()}>
          <div className={ this.state.headerTitle === this.props.title ? "dd-header-title" : "dd-header-title dd-header-selection" }>{headerTitle}</div>
          {listOpen
            ? <FontAwesome name="angle-up" size="2x"/>
            : <FontAwesome name="angle-down" size="2x"/>
          }
        </div>
        {listOpen && <ul className="dd-list" onClick={e => e.stopPropagation()}>
          {list.map((item)=> (
            <li className="dd-list-item" key={item.id} onClick={() => this.selectItem(item.title, item.id, item.key)}>{item.title} {item.selected && <FontAwesome name="check"/>}</li>
          ))}
        </ul>}
        <div className={ this.props.showTooltip ? "dropdownTooltip" : "dropdownTooltip hidden" }>
          <p>{ this.props.tooltipMessage ? this.props.tooltipMessage : "Make a selection from the dropdown" }</p>
        </div>
      </div>
    )
  }
}

export default Dropdown
