import React from 'react'
import './styles.css'

/*
 * Brand icon
 */
export const BrandIcon = ({
  className = '',
  fill = '#000000',
  fillRule = 'evenodd',
  fillOpacity = '0.03',
  stroke = '',
  strokeWidth = 1,
  width = '80',
  height = '80',
}) => <svg
  className={className + " svg-icon"}
  width={width}
  height={height}
  viewBox='0 0 30 30'
  xmlns='http://www.w3.org/2000/svg'
  stroke={stroke}
  strokeWidth={strokeWidth}
  fill={fill}
  fillOpacity={fillOpacity}>
  <path
    d="m50,50c0,-5.523 4.477,-10 10,-10s10,4.477 10,10s-4.477,10 -10,10c0,5.523 -4.477,10 -10,10s-10,-4.477 -10,-10s4.477,-10 10,-10zm-40,-40c0,-5.523 4.477,-10 10,-10s10,4.477 10,10s-4.477,10 -10,10c0,5.523 -4.477,10 -10,10s-10,-4.477 -10,-10s4.477,-10 10,-10zm10,8c4.418,0 8,-3.582 8,-8s-3.582,-8 -8,-8s-8,3.582 -8,8s3.582,8 8,8zm40,40c4.418,0 8,-3.582 8,-8s-3.582,-8 -8,-8s-8,3.582 -8,8s3.582,8 8,8z">
  </path>
</svg>


/*
    d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z'>
    v1. d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40'>
*/

/*
 * Networking: Radio symbol
 */
export const IconOne = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M15.684,16.959L10.879,8.52c0.886-0.343,1.517-1.193,1.517-2.186c0-1.296-1.076-2.323-2.396-2.323S7.604,5.037,7.604,6.333c0,0.993,0.63,1.843,1.517,2.186l-4.818,8.439c-0.189,0.311,0.038,0.708,0.412,0.708h10.558C15.645,17.667,15.871,17.27,15.684,16.959 M8.562,6.333c0-0.778,0.645-1.382,1.438-1.382s1.438,0.604,1.438,1.382c0,0.779-0.645,1.412-1.438,1.412S8.562,7.113,8.562,6.333 M5.55,16.726L10,8.91l4.435,7.815H5.55z M15.285,9.62c1.26-2.046,1.26-4.525,0-6.572c-0.138-0.223-0.064-0.512,0.162-0.646c0.227-0.134,0.521-0.063,0.658,0.16c1.443,2.346,1.443,5.2,0,7.546c-0.236,0.382-0.641,0.17-0.658,0.159C15.221,10.131,15.147,9.842,15.285,9.62 M13.395,8.008c0.475-1.063,0.475-2.286,0-3.349c-0.106-0.238,0.004-0.515,0.246-0.62c0.242-0.104,0.525,0.004,0.632,0.242c0.583,1.305,0.583,2.801,0,4.106c-0.214,0.479-0.747,0.192-0.632,0.242C13.398,8.523,13.288,8.247,13.395,8.008 M3.895,10.107c-1.444-2.346-1.444-5.2,0-7.546c0.137-0.223,0.431-0.294,0.658-0.16c0.226,0.135,0.299,0.424,0.162,0.646c-1.26,2.047-1.26,4.525,0,6.572c0.137,0.223,0.064,0.512-0.162,0.646C4.535,10.277,4.131,10.489,3.895,10.107 M5.728,8.387c-0.583-1.305-0.583-2.801,0-4.106c0.106-0.238,0.39-0.346,0.631-0.242c0.242,0.105,0.353,0.382,0.247,0.62c-0.475,1.063-0.475,2.286,0,3.349c0.106,0.238-0.004,0.515-0.247,0.62c-0.062,0.027-0.128,0.04-0.192,0.04C5.982,8.668,5.807,8.563,5.728,8.387">
  </path>
</svg>

/*
 * References: Speechbox symbol
 */
export const IconTwo = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M14.999,8.543c0,0.229-0.188,0.417-0.416,0.417H5.417C5.187,8.959,5,8.772,5,8.543s0.188-0.417,0.417-0.417h9.167C14.812,8.126,14.999,8.314,14.999,8.543 M12.037,10.213H5.417C5.187,10.213,5,10.4,5,10.63c0,0.229,0.188,0.416,0.417,0.416h6.621c0.229,0,0.416-0.188,0.416-0.416C12.453,10.4,12.266,10.213,12.037,10.213 M14.583,6.046H5.417C5.187,6.046,5,6.233,5,6.463c0,0.229,0.188,0.417,0.417,0.417h9.167c0.229,0,0.416-0.188,0.416-0.417C14.999,6.233,14.812,6.046,14.583,6.046 M17.916,3.542v10c0,0.229-0.188,0.417-0.417,0.417H9.373l-2.829,2.796c-0.117,0.116-0.71,0.297-0.71-0.296v-2.5H2.5c-0.229,0-0.417-0.188-0.417-0.417v-10c0-0.229,0.188-0.417,0.417-0.417h15C17.729,3.126,17.916,3.313,17.916,3.542 M17.083,3.959H2.917v9.167H6.25c0.229,0,0.417,0.187,0.417,0.416v1.919l2.242-2.215c0.079-0.077,0.184-0.12,0.294-0.12h7.881V3.959z">
  </path>
</svg>

/*
 * Skills growth: Signal symbol
 */
export const IconThree = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M11.709,7.438H8.292c-0.234,0-0.427,0.192-0.427,0.427v8.542c0,0.234,0.192,0.427,0.427,0.427h3.417c0.233,0,0.426-0.192,0.426-0.427V7.865C12.135,7.63,11.942,7.438,11.709,7.438 M11.282,15.979H8.719V8.292h2.563V15.979zM6.156,11.709H2.74c-0.235,0-0.427,0.191-0.427,0.426v4.271c0,0.234,0.192,0.427,0.427,0.427h3.417c0.235,0,0.427-0.192,0.427-0.427v-4.271C6.583,11.9,6.391,11.709,6.156,11.709 M5.729,15.979H3.167v-3.416h2.562V15.979zM17.261,3.167h-3.417c-0.235,0-0.427,0.192-0.427,0.427v12.812c0,0.234,0.191,0.427,0.427,0.427h3.417c0.234,0,0.427-0.192,0.427-0.427V3.594C17.688,3.359,17.495,3.167,17.261,3.167 M16.833,15.979h-2.562V4.021h2.562V15.979z">
  </path>
</svg>

/*
 * Compensation: Pie chart symbol
 */
export const IconFour = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M10.281,1.781C5.75,1.781,2.062,5.469,2.062,10s3.688,8.219,8.219,8.219S18.5,14.531,18.5,10S14.812,1.781,10.281,1.781M10.714,2.659c3.712,0.216,6.691,3.197,6.907,6.908h-6.907V2.659z M10.281,17.354c-4.055,0-7.354-3.298-7.354-7.354c0-3.911,3.067-7.116,6.921-7.341V10c0,0.115,0.045,0.225,0.127,0.305l5.186,5.189C13.863,16.648,12.154,17.354,10.281,17.354M15.775,14.882l-4.449-4.449h6.295C17.522,12.135,16.842,13.684,15.775,14.882">
  </path>
</svg>

/*
 * Personal branding: Person symbol
 */
export const IconFive = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z">
  </path>
</svg>

/*
 * One-on-one: Rotating arrows symbol
 */
export const IconSix = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M12.319,5.792L8.836,2.328C8.589,2.08,8.269,2.295,8.269,2.573v1.534C8.115,4.091,7.937,4.084,7.783,4.084c-2.592,0-4.7,2.097-4.7,4.676c0,1.749,0.968,3.337,2.528,4.146c0.352,0.194,0.651-0.257,0.424-0.529c-0.415-0.492-0.643-1.118-0.643-1.762c0-1.514,1.261-2.747,2.787-2.747c0.029,0,0.06,0,0.09,0.002v1.632c0,0.335,0.378,0.435,0.568,0.245l3.483-3.464C12.455,6.147,12.455,5.928,12.319,5.792 M8.938,8.67V7.554c0-0.411-0.528-0.377-0.781-0.377c-1.906,0-3.457,1.542-3.457,3.438c0,0.271,0.033,0.542,0.097,0.805C4.149,10.7,3.775,9.762,3.775,8.76c0-2.197,1.798-3.985,4.008-3.985c0.251,0,0.501,0.023,0.744,0.069c0.212,0.039,0.412-0.124,0.412-0.34v-1.1l2.646,2.633L8.938,8.67z M14.389,7.107c-0.34-0.18-0.662,0.244-0.424,0.529c0.416,0.493,0.644,1.118,0.644,1.762c0,1.515-1.272,2.747-2.798,2.747c-0.029,0-0.061,0-0.089-0.002v-1.631c0-0.354-0.382-0.419-0.558-0.246l-3.482,3.465c-0.136,0.136-0.136,0.355,0,0.49l3.482,3.465c0.189,0.186,0.568,0.096,0.568-0.245v-1.533c0.153,0.016,0.331,0.022,0.484,0.022c2.592,0,4.7-2.098,4.7-4.677C16.917,9.506,15.948,7.917,14.389,7.107 M12.217,15.238c-0.251,0-0.501-0.022-0.743-0.069c-0.212-0.039-0.411,0.125-0.411,0.341v1.101l-2.646-2.634l2.646-2.633v1.116c0,0.174,0.126,0.318,0.295,0.343c0.158,0.024,0.318,0.034,0.486,0.034c1.905,0,3.456-1.542,3.456-3.438c0-0.271-0.032-0.541-0.097-0.804c0.648,0.719,1.022,1.659,1.022,2.66C16.226,13.451,14.428,15.238,12.217,15.238">
  </path>
</svg>

/*
 * Interview prep: Star symbol
 */
export const IconSeven = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M17.684,7.925l-5.131-0.67L10.329,2.57c-0.131-0.275-0.527-0.275-0.658,0L7.447,7.255l-5.131,0.67C2.014,7.964,1.892,8.333,2.113,8.54l3.76,3.568L4.924,17.21c-0.056,0.297,0.261,0.525,0.533,0.379L10,15.109l4.543,2.479c0.273,0.153,0.587-0.089,0.533-0.379l-0.949-5.103l3.76-3.568C18.108,8.333,17.986,7.964,17.684,7.925 M13.481,11.723c-0.089,0.083-0.129,0.205-0.105,0.324l0.848,4.547l-4.047-2.208c-0.055-0.03-0.116-0.045-0.176-0.045s-0.122,0.015-0.176,0.045l-4.047,2.208l0.847-4.547c0.023-0.119-0.016-0.241-0.105-0.324L3.162,8.54L7.74,7.941c0.124-0.016,0.229-0.093,0.282-0.203L10,3.568l1.978,4.17c0.053,0.11,0.158,0.187,0.282,0.203l4.578,0.598L13.481,11.723z">
  </path>
</svg>

/*
 * Coaching: Navigation symbol
 */
export const IconEight = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M17.218,2.268L2.477,8.388C2.13,8.535,2.164,9.05,2.542,9.134L9.33,10.67l1.535,6.787c0.083,0.377,0.602,0.415,0.745,0.065l6.123-14.74C17.866,2.46,17.539,2.134,17.218,2.268 M3.92,8.641l11.772-4.89L9.535,9.909L3.92,8.641z M11.358,16.078l-1.268-5.613l6.157-6.157L11.358,16.078z">
  </path>
</svg>

/*
 * Career planning: Folded paper symbol
 */
export const IconNine = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M8.627,7.885C8.499,8.388,7.873,8.101,8.13,8.177L4.12,7.143c-0.218-0.057-0.351-0.28-0.293-0.498c0.057-0.218,0.279-0.351,0.497-0.294l4.011,1.037C8.552,7.444,8.685,7.667,8.627,7.885 M8.334,10.123L4.323,9.086C4.105,9.031,3.883,9.162,3.826,9.38C3.769,9.598,3.901,9.82,4.12,9.877l4.01,1.037c-0.262-0.062,0.373,0.192,0.497-0.294C8.685,10.401,8.552,10.18,8.334,10.123 M7.131,12.507L4.323,11.78c-0.218-0.057-0.44,0.076-0.497,0.295c-0.057,0.218,0.075,0.439,0.293,0.495l2.809,0.726c-0.265-0.062,0.37,0.193,0.495-0.293C7.48,12.784,7.35,12.562,7.131,12.507M18.159,3.677v10.701c0,0.186-0.126,0.348-0.306,0.393l-7.755,1.948c-0.07,0.016-0.134,0.016-0.204,0l-7.748-1.948c-0.179-0.045-0.306-0.207-0.306-0.393V3.677c0-0.267,0.249-0.461,0.509-0.396l7.646,1.921l7.654-1.921C17.91,3.216,18.159,3.41,18.159,3.677 M9.589,5.939L2.656,4.203v9.857l6.933,1.737V5.939z M17.344,4.203l-6.939,1.736v9.859l6.939-1.737V4.203z M16.168,6.645c-0.058-0.218-0.279-0.351-0.498-0.294l-4.011,1.037c-0.218,0.057-0.351,0.28-0.293,0.498c0.128,0.503,0.755,0.216,0.498,0.292l4.009-1.034C16.092,7.085,16.225,6.863,16.168,6.645 M16.168,9.38c-0.058-0.218-0.279-0.349-0.498-0.294l-4.011,1.036c-0.218,0.057-0.351,0.279-0.293,0.498c0.124,0.486,0.759,0.232,0.498,0.294l4.009-1.037C16.092,9.82,16.225,9.598,16.168,9.38 M14.963,12.385c-0.055-0.219-0.276-0.35-0.495-0.294l-2.809,0.726c-0.218,0.056-0.351,0.279-0.293,0.496c0.127,0.506,0.755,0.218,0.498,0.293l2.807-0.723C14.89,12.825,15.021,12.603,14.963,12.385">
  </path>
</svg>

/*
 * Certifications: Flag symbol
 */
export const IconTen = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M14.467,1.771H5.533c-0.258,0-0.47,0.211-0.47,0.47v15.516c0,0.414,0.504,0.634,0.802,0.331L10,13.955l4.136,4.133c0.241,0.241,0.802,0.169,0.802-0.331V2.241C14.938,1.982,14.726,1.771,14.467,1.771 M13.997,16.621l-3.665-3.662c-0.186-0.186-0.479-0.186-0.664,0l-3.666,3.662V2.711h7.994V16.621z">
  </path>
</svg>

/*
 * Introductions: Two people symbol
 */
export const IconEleven = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M15.573,11.624c0.568-0.478,0.947-1.219,0.947-2.019c0-1.37-1.108-2.569-2.371-2.569s-2.371,1.2-2.371,2.569c0,0.8,0.379,1.542,0.946,2.019c-0.253,0.089-0.496,0.2-0.728,0.332c-0.743-0.898-1.745-1.573-2.891-1.911c0.877-0.61,1.486-1.666,1.486-2.812c0-1.79-1.479-3.359-3.162-3.359S4.269,5.443,4.269,7.233c0,1.146,0.608,2.202,1.486,2.812c-2.454,0.725-4.252,2.998-4.252,5.685c0,0.218,0.178,0.396,0.395,0.396h16.203c0.218,0,0.396-0.178,0.396-0.396C18.497,13.831,17.273,12.216,15.573,11.624 M12.568,9.605c0-0.822,0.689-1.779,1.581-1.779s1.58,0.957,1.58,1.779s-0.688,1.779-1.58,1.779S12.568,10.427,12.568,9.605 M5.06,7.233c0-1.213,1.014-2.569,2.371-2.569c1.358,0,2.371,1.355,2.371,2.569S8.789,9.802,7.431,9.802C6.073,9.802,5.06,8.447,5.06,7.233 M2.309,15.335c0.202-2.649,2.423-4.742,5.122-4.742s4.921,2.093,5.122,4.742H2.309z M13.346,15.335c-0.067-0.997-0.382-1.928-0.882-2.732c0.502-0.271,1.075-0.429,1.686-0.429c1.828,0,3.338,1.385,3.535,3.161H13.346z">
  </path>
</svg>

/*
 * Soft skills: Speech bubbles symbol
 */
export const IconTwelve = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M17.659,3.681H8.468c-0.211,0-0.383,0.172-0.383,0.383v2.681H2.341c-0.21,0-0.383,0.172-0.383,0.383v6.126c0,0.211,0.172,0.383,0.383,0.383h1.532v2.298c0,0.566,0.554,0.368,0.653,0.27l2.569-2.567h4.437c0.21,0,0.383-0.172,0.383-0.383v-2.681h1.013l2.546,2.567c0.242,0.249,0.652,0.065,0.652-0.27v-2.298h1.533c0.211,0,0.383-0.172,0.383-0.382V4.063C18.042,3.853,17.87,3.681,17.659,3.681 M11.148,12.87H6.937c-0.102,0-0.199,0.04-0.27,0.113l-2.028,2.025v-1.756c0-0.211-0.172-0.383-0.383-0.383H2.724V7.51h5.361v2.68c0,0.21,0.172,0.382,0.383,0.382h2.68V12.87z M17.276,9.807h-1.533c-0.211,0-0.383,0.172-0.383,0.383v1.755L13.356,9.92c-0.07-0.073-0.169-0.113-0.27-0.113H8.851v-5.36h8.425V9.807z">
  </path>
</svg>

/*
 * Guidance: Map symbol
 */
export const IconThirteen = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M18.092,5.137l-3.977-1.466h-0.006c0.084,0.042-0.123-0.08-0.283,0H13.82L10,5.079L6.178,3.671H6.172c0.076,0.038-0.114-0.076-0.285,0H5.884L1.908,5.137c-0.151,0.062-0.25,0.207-0.25,0.369v10.451c0,0.691,0.879,0.244,0.545,0.369l3.829-1.406l3.821,1.406c0.186,0.062,0.385-0.029,0.294,0l3.822-1.406l3.83,1.406c0.26,0.1,0.543-0.08,0.543-0.369V5.506C18.342,5.344,18.242,5.199,18.092,5.137 M5.633,14.221l-3.181,1.15V5.776l3.181-1.15V14.221z M9.602,15.371l-3.173-1.15V4.626l3.173,1.15V15.371z M13.57,14.221l-3.173,1.15V5.776l3.173-1.15V14.221z M17.547,15.371l-3.182-1.15V4.626l3.182,1.15V15.371z">
  </path>
</svg>

/*
 * Video chats: Video symbol
 */
export const IconFourteen = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M17.919,4.633l-3.833,2.48V6.371c0-1-0.815-1.815-1.816-1.815H3.191c-1.001,0-1.816,0.814-1.816,1.815v7.261c0,1.001,0.815,1.815,1.816,1.815h9.079c1.001,0,1.816-0.814,1.816-1.815v-0.739l3.833,2.478c0.428,0.226,0.706-0.157,0.706-0.377V5.01C18.625,4.787,18.374,4.378,17.919,4.633 M13.178,13.632c0,0.501-0.406,0.907-0.908,0.907H3.191c-0.501,0-0.908-0.406-0.908-0.907V6.371c0-0.501,0.407-0.907,0.908-0.907h9.079c0.502,0,0.908,0.406,0.908,0.907V13.632zM17.717,14.158l-3.631-2.348V8.193l3.631-2.348V14.158z">
  </path>
</svg>

/*
 * Honest feedback: Bubble chat symbol
 */
export const IconFifteen = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M17.657,2.982H2.342c-0.234,0-0.425,0.191-0.425,0.426v10.21c0,0.234,0.191,0.426,0.425,0.426h3.404v2.553c0,0.397,0.48,0.547,0.725,0.302l2.889-2.854h8.298c0.234,0,0.426-0.191,0.426-0.426V3.408C18.083,3.174,17.892,2.982,17.657,2.982M17.232,13.192H9.185c-0.113,0-0.219,0.045-0.3,0.124l-2.289,2.262v-1.96c0-0.233-0.191-0.426-0.425-0.426H2.767V3.833h14.465V13.192z M10,7.237c-0.821,0-1.489,0.668-1.489,1.489c0,0.821,0.668,1.489,1.489,1.489c0.821,0,1.488-0.668,1.488-1.489C11.488,7.905,10.821,7.237,10,7.237 M10,9.364c-0.352,0-0.638-0.288-0.638-0.638c0-0.351,0.287-0.638,0.638-0.638c0.351,0,0.638,0.287,0.638,0.638C10.638,9.077,10.351,9.364,10,9.364 M14.254,7.237c-0.821,0-1.489,0.668-1.489,1.489c0,0.821,0.668,1.489,1.489,1.489s1.489-0.668,1.489-1.489C15.743,7.905,15.075,7.237,14.254,7.237 M14.254,9.364c-0.351,0-0.638-0.288-0.638-0.638c0-0.351,0.287-0.638,0.638-0.638c0.352,0,0.639,0.287,0.639,0.638C14.893,9.077,14.605,9.364,14.254,9.364 M5.746,7.237c-0.821,0-1.489,0.668-1.489,1.489c0,0.821,0.668,1.489,1.489,1.489c0.821,0,1.489-0.668,1.489-1.489C7.234,7.905,6.566,7.237,5.746,7.237 M5.746,9.364c-0.351,0-0.638-0.288-0.638-0.638c0-0.351,0.287-0.638,0.638-0.638c0.351,0,0.638,0.287,0.638,0.638C6.384,9.077,6.096,9.364,5.746,9.364">
  </path>
</svg>

/*
 * Knowledge sharing: Sharing symbol
 */
export const IconSixteen = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M14.68,12.621c-0.9,0-1.702,0.43-2.216,1.09l-4.549-2.637c0.284-0.691,0.284-1.457,0-2.146l4.549-2.638c0.514,0.661,1.315,1.09,2.216,1.09c1.549,0,2.809-1.26,2.809-2.808c0-1.548-1.26-2.809-2.809-2.809c-1.548,0-2.808,1.26-2.808,2.809c0,0.38,0.076,0.741,0.214,1.073l-4.55,2.638c-0.515-0.661-1.316-1.09-2.217-1.09c-1.548,0-2.808,1.26-2.808,2.809s1.26,2.808,2.808,2.808c0.9,0,1.702-0.43,2.217-1.09l4.55,2.637c-0.138,0.332-0.214,0.693-0.214,1.074c0,1.549,1.26,2.809,2.808,2.809c1.549,0,2.809-1.26,2.809-2.809S16.229,12.621,14.68,12.621M14.68,2.512c1.136,0,2.06,0.923,2.06,2.06S15.815,6.63,14.68,6.63s-2.059-0.923-2.059-2.059S13.544,2.512,14.68,2.512M5.319,12.061c-1.136,0-2.06-0.924-2.06-2.06s0.923-2.059,2.06-2.059c1.135,0,2.06,0.923,2.06,2.059S6.454,12.061,5.319,12.061M14.68,17.488c-1.136,0-2.059-0.922-2.059-2.059s0.923-2.061,2.059-2.061s2.06,0.924,2.06,2.061S15.815,17.488,14.68,17.488">
  </path>
</svg>

/*
 * Recognition: Award symbol
 */
export const IconSeventeen = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M15.94,10.179l-2.437-0.325l1.62-7.379c0.047-0.235-0.132-0.458-0.372-0.458H5.25c-0.241,0-0.42,0.223-0.373,0.458l1.634,7.376L4.06,10.179c-0.312,0.041-0.446,0.425-0.214,0.649l2.864,2.759l-0.724,3.947c-0.058,0.315,0.277,0.554,0.559,0.401l3.457-1.916l3.456,1.916c-0.419-0.238,0.56,0.439,0.56-0.401l-0.725-3.947l2.863-2.759C16.388,10.604,16.254,10.22,15.94,10.179M10.381,2.778h3.902l-1.536,6.977L12.036,9.66l-1.655-3.546V2.778z M5.717,2.778h3.903v3.335L7.965,9.66L7.268,9.753L5.717,2.778zM12.618,13.182c-0.092,0.088-0.134,0.217-0.11,0.343l0.615,3.356l-2.938-1.629c-0.057-0.03-0.122-0.048-0.184-0.048c-0.063,0-0.128,0.018-0.185,0.048l-2.938,1.629l0.616-3.356c0.022-0.126-0.019-0.255-0.11-0.343l-2.441-2.354l3.329-0.441c0.128-0.017,0.24-0.099,0.295-0.215l1.435-3.073l1.435,3.073c0.055,0.116,0.167,0.198,0.294,0.215l3.329,0.441L12.618,13.182z">
  </path>
</svg>

/*
 * Resume development: Business card symbol
 */
export const IconEighteen = ({
  className = '',
  fill = '#FFFFFF',
  stroke = '',
  strokeWidth = 1,
}) => <svg
  className={className + " svg-icon"}
  viewBox="0 0 20 20">
  <path
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    d="M8.749,9.934c0,0.247-0.202,0.449-0.449,0.449H4.257c-0.247,0-0.449-0.202-0.449-0.449S4.01,9.484,4.257,9.484H8.3C8.547,9.484,8.749,9.687,8.749,9.934 M7.402,12.627H4.257c-0.247,0-0.449,0.202-0.449,0.449s0.202,0.449,0.449,0.449h3.145c0.247,0,0.449-0.202,0.449-0.449S7.648,12.627,7.402,12.627 M8.3,6.339H4.257c-0.247,0-0.449,0.202-0.449,0.449c0,0.247,0.202,0.449,0.449,0.449H8.3c0.247,0,0.449-0.202,0.449-0.449C8.749,6.541,8.547,6.339,8.3,6.339 M18.631,4.543v10.78c0,0.248-0.202,0.45-0.449,0.45H2.011c-0.247,0-0.449-0.202-0.449-0.45V4.543c0-0.247,0.202-0.449,0.449-0.449h16.17C18.429,4.094,18.631,4.296,18.631,4.543 M17.732,4.993H2.46v9.882h15.272V4.993z M16.371,13.078c0,0.247-0.202,0.449-0.449,0.449H9.646c-0.247,0-0.449-0.202-0.449-0.449c0-1.479,0.883-2.747,2.162-3.299c-0.434-0.418-0.714-1.008-0.714-1.642c0-1.197,0.997-2.246,2.133-2.246s2.134,1.049,2.134,2.246c0,0.634-0.28,1.224-0.714,1.642C15.475,10.331,16.371,11.6,16.371,13.078M11.542,8.137c0,0.622,0.539,1.348,1.235,1.348s1.235-0.726,1.235-1.348c0-0.622-0.539-1.348-1.235-1.348S11.542,7.515,11.542,8.137 M15.435,12.629c-0.214-1.273-1.323-2.246-2.657-2.246s-2.431,0.973-2.644,2.246H15.435z">
  </path>
</svg>

export const NetworkingIcon = ({
  style = {},
  fill = '',
  stroke = '#4284F4',
  strokeWidth = '20',
  strokeOpacity = '1',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 640 640',
  opacity = '1',
  fillOpacity = '0',
}) => <svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  viewBox={viewBox}
  width={width}
  height={height}
  className={className}
  style={style}>
  <path
    d="M196.83 165.98C196.83 204.53 165.54 235.83 126.98 235.83C88.43 235.83 57.13 204.53 57.13 165.98C57.13 127.43 88.43 96.13 126.98 96.13C165.54 96.13 196.83 127.43 196.83 165.98Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="b3fqmbJIjI">
  </path>
  <path
    d="M205.65 562.97C205.65 444.17 205.65 369.92 205.65 340.22C205.65 331.98 204.29 323.8 201.62 316C197.27 303.33 190.25 293.69 180.55 287.09C167.44 278.17 149.58 273.4 126.98 272.77C103.62 273.4 85.38 278.17 72.27 287.09C63.17 293.28 56.68 302.28 52.81 314.07C49.83 323.15 48.32 332.65 48.32 342.2C48.32 367.49 48.32 430.71 48.32 531.85"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="g1d4nxpot">
  </path>
  <path
    d="M391.69 126.44C391.69 165 360.39 196.29 321.84 196.29C283.29 196.29 251.99 165 251.99 126.44C251.99 87.89 283.29 56.59 321.84 56.59C360.39 56.59 391.69 87.89 391.69 126.44Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="hbi46hT0U">
  </path>
  <path
    d="M400.51 588.77C400.51 435.12 400.51 339.1 400.51 300.69C400.51 292.45 399.14 284.26 396.47 276.47C392.13 263.79 385.11 254.15 375.41 247.55C362.3 238.63 344.44 233.86 321.84 233.24C298.48 233.86 280.24 238.63 267.13 247.55C258.02 253.75 251.54 262.74 247.67 274.53C244.69 283.61 243.18 293.11 243.18 302.66C243.18 340.81 243.18 436.18 243.18 588.77"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="e1W52dK2RJ">
  </path>
  <path
    d="M585.6 165.98C585.6 204.53 554.3 235.83 515.75 235.83C477.2 235.83 445.9 204.53 445.9 165.98C445.9 127.43 477.2 96.13 515.75 96.13C554.3 96.13 585.6 127.43 585.6 165.98Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="aB1Ap5lqp">
  </path>
  <path
    d="M594.42 531.84C594.42 429.65 594.42 365.77 594.42 340.22C594.42 331.98 593.06 323.8 590.38 316C586.04 303.33 579.02 293.69 569.32 287.09C556.21 278.17 538.35 273.4 515.75 272.77C492.39 273.4 474.15 278.17 461.04 287.09C451.94 293.28 445.45 302.28 441.58 314.07C438.6 323.15 437.09 332.65 437.09 342.2C437.09 371.64 437.09 445.23 437.09 562.97"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="bIOdQeNBz">
  </path>
</svg>

export const CoachingIcon = ({
  style = {},
  fill = '',
  stroke = '#4284F4',
  strokeWidth = '20',
  strokeOpacity = '1',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 640 640',
  opacity = '1',
  fillOpacity = '0',
}) => <svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  viewBox={viewBox}
  width={width}
  height={height}
  className={className}
  style={style}>
  <path
    d="M188.83 165.98C188.83 204.53 157.54 235.83 118.98 235.83C80.43 235.83 49.13 204.53 49.13 165.98C49.13 127.43 80.43 96.13 118.98 96.13C157.54 96.13 188.83 127.43 188.83 165.98Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="ffGfmtCOE">
  </path>
  <path
    d="M197.65 562.97C197.65 444.17 197.65 369.92 197.65 340.22C197.65 331.98 196.29 323.8 193.62 316C189.27 303.33 182.25 293.69 172.55 287.09C159.44 278.17 141.58 273.4 118.98 272.77C95.62 273.4 77.38 278.17 64.27 287.09C55.17 293.28 48.68 302.28 44.81 314.07C41.83 323.15 40.32 332.65 40.32 342.2C40.32 365.19 40.32 422.66 40.32 514.62"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="h10mVEP2q">
  </path>
  <path
    d="M593.6 165.98C593.6 204.53 562.3 235.83 523.75 235.83C485.2 235.83 453.9 204.53 453.9 165.98C453.9 127.43 485.2 96.13 523.75 96.13C562.3 96.13 593.6 127.43 593.6 165.98Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="ab7CcErni">
  </path>
  <path
    d="M602.42 514.61C602.42 421.61 602.42 363.48 602.42 340.22C602.42 331.98 601.06 323.8 598.38 316C594.04 303.33 587.02 293.69 577.32 287.09C564.21 278.17 546.35 273.4 523.75 272.77C500.39 273.4 482.15 278.17 469.04 287.09C459.94 293.28 453.45 302.28 449.58 314.07C446.6 323.15 445.09 332.65 445.09 342.2C445.09 371.64 445.09 445.23 445.09 562.97"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="fPFOElnLP">
  </path>
  <path
    d="M301.8 216.83L242.07 288.78L301.8 360.73"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="e2yLab0eNE">
  </path>
  <path
    d="M345.07 216.83L404.8 288.78C404.55 289.08 399.45 295.22 389.5 307.21C379.54 319.2 364.73 337.04 345.07 360.73"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="ddaXDkkFl">
  </path>
</svg>

export const ReferencesIcon = ({
  style = {},
  fill = '',
  stroke = '#4284F4',
  strokeWidth = '20',
  strokeOpacity = '1',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 640 640',
  opacity = '1',
  fillOpacity = '0',
}) => <svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  viewBox={viewBox}
  width={width}
  height={height}
  className={className}
  style={style}>
  <path
    d="M185.82 165.98L49.13 330.63L185.82 495.29"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="a11hArhwqb">
  </path>
  <path
    d="M456.91 169.74L593.6 334.39C593.04 335.06 581.37 349.12 558.59 376.56C535.81 404.01 501.92 444.83 456.91 499.04"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="a4mr0i27Oo">
  </path>
  <path
    d="M355.61 334.39C355.61 354.04 339.65 370 320 370C300.35 370 284.39 354.04 284.39 334.39C284.39 314.74 300.35 298.78 320 298.78C339.65 298.78 355.61 314.74 355.61 334.39Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth="16"
    strokeOpacity={strokeOpacity}
    id="a2xALMvLtB">
  </path>
  <path
    d="M480.69 334.39C480.69 354.04 464.74 370 445.09 370C425.43 370 409.48 354.04 409.48 334.39C409.48 314.74 425.43 298.78 445.09 298.78C464.74 298.78 480.69 314.74 480.69 334.39Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth="16"
    strokeOpacity={strokeOpacity}
    id="eIx96jZGu">
  </path>
  <path
    d="M224.44 334.39C224.44 354.04 208.49 370 188.83 370C169.18 370 153.23 354.04 153.23 334.39C153.23 314.74 169.18 298.78 188.83 298.78C208.49 298.78 224.44 314.74 224.44 334.39Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth="16"
    strokeOpacity={strokeOpacity}
    id="f3igsByNQq">
  </path>
</svg>

export const CompensationIcon = ({
  style = {},
  fill = '',
  stroke = '#4284F4',
  strokeWidth = '20',
  strokeOpacity = '1',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 640 640',
  opacity = '1',
  fillOpacity = '0',
}) => <svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  viewBox={viewBox}
  width={width}
  height={height}
  className={className}
  style={style}>
  <path
    d="M474.99 222.99C474.99 308.53 405.54 377.98 320 377.98C234.46 377.98 165.01 308.53 165.01 222.99C165.01 137.45 234.46 68.01 320 68.01C405.54 68.01 474.99 137.45 474.99 222.99Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="aeWVliLXW">
  </path>
  <path
    d="M440.54 318L446.16 538.77L319.8 453.38L197.84 538.77L197.84 318"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="c1LHn3b3SG">
  </path>
  <path
    d="M404.27 222.99C404.27 269.5 366.51 307.26 320 307.26C273.49 307.26 235.73 269.5 235.73 222.99C235.73 176.48 273.49 138.72 320 138.72C366.51 138.72 404.27 176.48 404.27 222.99Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="g1ohFTQPJ">
  </path>
</svg>

export const SkillsIcon = ({
  style = {},
  fill = '',
  stroke = '#4284F4',
  strokeWidth = '20',
  strokeOpacity = '1',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 640 640',
  opacity = '1',
  fillOpacity = '0',
}) => <svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  viewBox={viewBox}
  width={width}
  height={height}
  className={className}
  style={style}>
  <path
    d="M146.36 107.07L193.55 107.07L193.55 599.2L146.36 599.2L146.36 107.07Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="b6BWfkM">
  </path>
  <path
    d="M226.55 353.13L473.74 353.13L520.93 107.07L226.55 107.07"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="a4Tu6My6S8">
  </path>
  <path
    d="M226.55 314.8L442.58 314.8L475.07 145.4L226.55 145.4"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="beBgt5b9p">
  </path>
</svg>

export const BrandingIcon = ({
  style = {},
  fill = '',
  stroke = '#4284F4',
  strokeWidth = '20',
  strokeOpacity = '1',
  width = '100%',
  height = '100%',
  className = '',
  viewBox = '0 0 640 640',
  opacity = '1',
  fillOpacity = '0',
}) => <svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  viewBox={viewBox}
  width={width}
  height={height}
  className={className}
  style={style}>
  <path
    d="M316.51 550.49L316.51 299.92C315.33 281.94 310.71 267.78 302.67 257.43C297.92 251.33 289 246.2 275.89 242.02C262.61 237.79 248.42 237.29 234.88 240.59C223.25 243.41 214.06 247.86 207.31 253.94C195.82 264.29 190.07 279.61 190.07 299.92L190.07 575.78"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="h74EEogVEp">
  </path>
  <path
    d="M310.18 133.83C310.18 165.23 284.69 190.72 253.29 190.72C221.89 190.72 196.39 165.23 196.39 133.83C196.39 102.43 221.89 76.93 253.29 76.93C284.69 76.93 310.18 102.43 310.18 133.83Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="b60KT8yNS">
  </path>
  <path
    d="M467.79 469.35L467.79 183.72L441.93 134.72L416.07 183.72L416.07 469.35L467.79 469.35Z"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="et560GAeM">
  </path>
  <path
    d="M467.79 469.35L467.79 508.29L416.07 508.29L416.07 469.35"
    opacity={opacity}
    fill={fill}
    fillOpacity={fillOpacity}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeOpacity={strokeOpacity}
    id="aFDZ6ha5O">
  </path>
</svg>
